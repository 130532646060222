
.datainput{

  position: relative;

  //input[type='text']:focus + .datainput__results{

    //  display: block;

//  }

  &__results{

      &--active{
          display: block;
      }

      display:none;
      border:1px solid #dedede;

      height:320px;
      width: 100%;
      overflow: hidden;
      overflow-y: auto;
      position: absolute;
      bottom:0;
      top:100%;
      z-index: 2;
      background: #fff;


      li{
        padding:15px;
        transition: background-color 150ms ease-in;

        &:hover{
          color:#fff;
          background-color: $color-primary-02;
          cursor: pointer;
          cursor: hand;
        }

      }

  }

  &__actions{

    width: 92px;
    height: 43px;
    background-color: $color-gray-03;
    //border:1px solid #dedede;
    border-radius: 0/3px/3px/0;
    //background-color: red;
    border-left:1px solid #dedede;
    position: absolute;
    right:1px;
    bottom:1px;
    @include clearfix();

    span{

      display: block;
      float: left;
      width: 45px;
      height: 43px;

      &:first-child{
        border-right:1px solid #dedede;
      }
      //background-color: blue;
      background-position: center center;
      background-repeat: no-repeat;
      overflow: hidden;
      text-indent: -50000em;
      opacity: 0.4;
      transition: opacity 150ms ease-in;

      &:hover{
        cursor: hand;
        cursor:pointer;
        opacity: 1;
      }

    }


    &__add{
        background-image: url("../img/ico-check-black.png");
        background-size: 11px auto;
    }

    &__remove{
        background-image: url("../img/ico-close-black.png");
        background-size: 11px auto;
    }

  }


}
