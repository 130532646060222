.page-header{

  margin-bottom: ($grid-gutter*2);
  position: relative;

  &--no-padding{

  	 .wrap{
  		padding:0 !important;

  	}
  }

  .wrap{
    padding:0 $grid-gutter;
  //  @include clearfix();
    position: relative;
  }

  h1{
    padding: 0;
    margin: 0 0 10px 0;
    line-height: 45px;
  }

  &__meta{
  	//float:right;
   //line-height: 45px;
  }

  &__back{

    padding-right: 55px;
    position: absolute;
    right:0px;
    top:50%;
    transform: translateY(-50%);
    font-size: 0.9rem;

    &:after{

      display: block;
      position: absolute;
      right:0;
      top:50%;
      transform: translateY(-50%);

      content:'';
      width:45px;
      height: 45px;
      text-indent: -5000em;
      overflow: hidden;
      margin-right: 15px;
      background-image: url(../img/ico-arrow-right-black.png);
      background-size: 8px auto;
      background-position: center center;
      background-repeat: no-repeat;
      opacity: 0.3;
  		transition: opacity 150ms ease-in;

    }

    &:hover{
      opacity: 1;
      text-decoration: none;
    }

  }

  h1, &__back{
    //float:left;
  }

  .button{
    //float: right;
  }



}
