







.loading-screen {


  position: fixed;
  z-index: 999;
  left:0;
  top:0;
  bottom:0;
  right:0;
  overflow:hidden;
  background: rgba(0,0,0,0.5);
  display: none;


  .loader-wrap{

    position: absolute;
    top:50%;
    left:50%;
    width:100px;
    height:100px;
    //background: #fff;
    transform: translate(-50%,-50%);

  }

  &--show{
    display: block;
  }


  &--hide{


  }

}



.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {

  //margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
