.tabbed-navigation{

	display:inline-block;
	@include clearfix();
	margin-bottom:($base-unit*1.5);
	border-bottom:1px solid #dedede;
	width:100%;
	//padding-left:15px;


	li{
		display:block;
		float:left;
		//border-bottom:1px solid #dedede;
		//width:160px;
		padding:15px 0px;
		text-align:center;
		border-radius:5px 5px 0 0;
		margin-left:-1px;
		margin-bottom:-1px;
		margin-right: 45px;
		//background-color:$color-gray-03;
		//font-size:0.9rem;
		color:$color-primary-01;
		//margin-right:5px;

		&.active{
			//background-color:#fff;
			border-bottom:4px solid $color-primary-02;
		}



		&:hover{
			cursor:hand;
			cursor:pointer;
		}
	}

}
