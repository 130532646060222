.logwork{

  height: 100%;
  position: fixed;
  top:0;
  z-index: 999;
  visibility: hidden;
  transition: visibility 150ms ease-in;


  &--active{

    visibility: visible;
    opacity: 1;

    .logwork__transparant{
        opacity: 1;
    }
    .logwork__inner{
        opacity: 1;
        transform: translateX(0%);
    }

    .logwork__close{
        opacity: 0.5;
        transform: translateY(0);
    }

  }


  &__close{

    position: fixed;;
    right: 15px;
    top:15px;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    display: block;
    z-index: 3;
    overflow: hidden;
    text-indent: -5000em;
    background-position:center center;
    background-repeat:no-repeat;
    background-image:url(../img/ico-close-black.png);
    background-size:14px auto;
    opacity: 0;

    transition: opacity 100ms ease-in 500ms;


    &:hover{
      transition: opacity 150ms ease-in;
      opacity: 1;
      cursor: hand;
      cursor: pointer;
    }
  }

  &__inner{

    position: fixed;
    z-index: 1000;
    top:0;
    right:0;
    background-color: #fff;
    height: 100%;
    overflow: hidden;
    overflow-y: visible;

    z-index: 2;
    padding: 40px;
    max-width:420px;
    width: 90%;
    transform: translateX(100%);
    transition: transform 150ms ease-in 250ms;
    text-align: center;


  }

  &__transparant{

    background-color: rgba(0,0,0,0.5);
    left:0;
    top:0;
    right:0;
    bottom:0;
    position: fixed;
    z-index: 1;
    opacity: 0;
    transition: opacity 150ms ease-in;

  }

  &__table{
    display: table;
    table-layout: inherit;
   height: 100%;
   width: 100%;
  }

  &__content{
    height: 100%;
    display: table-cell;
    vertical-align: middle;

  }


  &__title{
    display: block;
    margin-bottom: 15px;
    font-size: 2.0rem;
    //text-transform: uppercase;

  }


  &__subtitle{
    display: block;
    margin-bottom: 15px;
  }


  &__timer{
    display: block;
    margin-bottom: 60px;
    font-size: 1.6rem;
    font-weight: 500;

  }

  &__input{
    //max-width: 220px;
    margin: 0 auto 30px auto;
    label{
      display: block;
      margin-bottom: 8px;
    }
  }


  .button{
    width: 100%;
  }


}
