

.timer{

  //width: 320px;
  background-color: #fff;//$color-primary-01;
  //color: #fff;
  position: fixed;
  z-index:888;
  bottom:40px;
  right:40px;
  display: block;

box-shadow: 0 5px 9px 0 rgba(0,0,0,0.20);

  @include clearfix();


  &__cell{
    display: block;
    float: left;
    height: 80px;
    position: relative;

    &:nth-child(1){
      width: 80%;
      width: 200px;

    }

    &:nth-child(2){
      width: 80px;
      border-left: 1px solid rgba(255,255,255,0.2);

    }
    &:nth-child(3){
      width: 80px;
      border-left: 1px solid rgba(255,255,255,0.2);

      div {
        background-color:green;
      }
    }

  }

  &__time{
    font-size: 2.0rem;
    position: absolute;
    top:50%;
    left:30px;
    transform: translateY(-50%);
  }

  &__button{

    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    background-color: $color-primary-02;
    color: #fff;
    transition: background-color 150ms ease-in;

    &--stop{
      background-color: red;
    }

    &:hover{
        background-color: darken($color-primary-02,20%);
        cursor: pointer;
        cursor: hand;
    }

    span{


    }

  }

}
