


.button-show-filter{
  margin-bottom: 30px;
  display: block;
  @include respond-to(small){
    &{
    display: none;
    }
  }
}

.filter-wrap{
  display: none;
  @include respond-to(small){
    display: block;
  }

  &--show{
    display: block !important;
  }
}




.clear-filters{

  margin-bottom: 30px;

}



/* ----------------------------------------------------------------------
 *
 * .qseach
 *
 -----------------------------------------------------------------------*/


.qsearch{

  margin-bottom: 30px;
  position: relative;
  height: 40px;
  width: 100%;

	label{

    display: none;
	}

	input[type="text"]{
		border:1px solid #dedede;
		padding: 0 8px 0 35px;
		margin:0 0 8px 0;;
		height:40px;
		line-height:40px;
		width:100%;
		font-size: 1rem;
		color: $color-gray-01;
		font-weight: 300;
		font-family: $font-stack;
		border-radius:4px;
	}



  &:before{

    display: block;
    content: '';
    position: absolute;
    z-index: 2;
    top:50%;
    left:5px;
    transform: translateY(-50%);

    width:30px;
    height:45px;
    text-indent:-5000em;
    overflow:hidden;
    background-size: 18px auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../img/ico-search.png);
    //opacity: 0.3;
  //  transition: opacity 150ms ease-in;


  }


  &__clear{

    display: block;
    position: absolute;
    z-index: 2;
    top:50%;
    right:5px;
    transform: translateY(-50%);

    width:30px;
    height:45px;
    text-indent:-5000em;
    overflow:hidden;
    background-size: 18px auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../img/ico-clear.png);

    opacity: 0.3;
    transition: opacity 150ms ease-in;

    &:hover{
      cursor: hand;
      cursor: pointer;
      opacity: 1;

    }

  }



}



/* ----------------------------------------------------------------------
 *
 * .filter
 *
 -----------------------------------------------------------------------*/


.filter{


	//border:1px solid red;


	display: block;
	margin: 0 0 15px 0;
	padding: 0 0 15px 0;
	border-bottom:1px solid #E6E6E6;
	position:relative;

	&:last-child{
		border-bottom: none;
		margin: 0 0 0 0;
		padding: 0 0 0 0;

	}


	header{
		font-size:1.0rem;
		font-weight:400;
		padding:0;
		margin: 0 0 10px 0;
		display:block;
		color: $color-primary-01;
	}

	.remove{

		&.show{
			display:block;
		}

		display:none;
		width:20px;
		height:20px;
		overflow:hidden;
		text-indent:-5000em;
		position:absolute;
		right:0;
		top:3px;
		background-image:url("../img/ico-remove.png");
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 11px auto;

		opacity: 0.2;
		cursor:hand;

		&:hover{
			opacity: 0.6;
		}



	}

	ul{

		li{
			margin: 0 0 10px 0;
			line-height:24px;


			label{
				//text-transform:lowercase;
				display:block;

				&:first-letter{
					//text-transform:uppercase;
					//color:red !important;
				}
			}

		}



	}

	input[type="checkbox"] {

	    display:none;

	}


	input[type="checkbox"] + label span {

	    display:inline-block;
	    width:17px;
	    height:17px;
	    margin:-1px 12px 0 0;
	    vertical-align:middle;
	   	background-color:#F7F7F7;
	    border:1px solid #ccc;
	    cursor:pointer;
	    position:relative;

	    em{
	    	display:none;
	    }


	}

	input[type="checkbox"]:checked + label span {

	    /*
	    background-image:url("../img/ico-checked.png");
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 10px auto;
	   */

	    em{

	   	  position:absolute;
	   	  //border-radius: 50%;
	   	  width:7px;
	   	  height:7px;
	   	  background:#909090;
	   	  display:block;
	   	  left:4px;
	   	  top:4px;

	   }


	}



	input[type="radio"] {

	    display:none;

	}


	input[type="radio"] + label span {

	    display:inline-block;
	    width:18px;
	    height:18px;
	    margin:-1px 8px 0 0;
	    vertical-align:middle;
	    background-color:#F7F7F7;
	    border:1px solid #ccc;
	    cursor:pointer;
	    border-radius: 50%;
	    position:relative;


	    em{
	    	display:none;
	    }




	}

	input[type="radio"]:checked + label span {


	   em{

	   	  position:absolute;
	   	  border-radius: 50%;
	   	  width:8px;
	   	  height:8px;
	   	  background:#909090;
	   	  display:block;
	   	  left:4px;
	   	  top:4px;

	   }

	}



	.more{

		font-size:0.9rem;
		margin: 0 0 10px 0;
		color: black; //$content-text-color-secondary;

	}


	.qsearch{

			border:1px solid #dedede;
			padding: 0 8px;
			margin:0 0 8px 0;;
			height:33px;
			line-height:33px;
			width:100%;
      font-size: 1rem;



	}

}

html.svg .object-overview-filter{

	&.qsearch button{
		background-image:url("../img/ico-search-white.svg");
	}


}
