
$file-browser-height: 600px;
$file-browser-preview-width: 400px;


.file-browser{



  &__content{
    float: left;
    width: calc(100% - #{$file-browser-preview-width});
    //background-color: red;
    padding: $base-unit;
    min-height: $file-browser-height;

  }


  &__aside{
    float: left;
    width: $file-browser-preview-width;
    //  background-color: yellow;
    padding: $base-unit;
    background-color: $color-gray-02;
    background-color: #fff;
    min-height: $file-browser-height;

  }

  //background-color: blue;

  &__footer{

    width: 100%;
    clear: both;
    text-align: right;
    padding: $base-unit;
    border-top: 1px solid #dedede;
    background-color: #fff;

    .button{
      margin-left: 15px;
    }
  }

}
