@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500');

$font-stack: 'Raleway', sans-serif;




*, *:after, *:before{
	box-sizing: border-box;
}

span {
	font-size:14px;
}

html{

	font-size:14px;
	height:100%;
	background-color: $color-gray-01;
	//background-color: $color-gray-02;
}


body{

	font-family: $font-stack;
	color:$color-primary-01;
	background-color: $color-gray-01;
	//background-color: $color-gray-02;
	font-weight: 300;
	overflow-x: hidden;
	height:100%;

}


h1,h2,h3,h4,h5{

	padding:0;
	margin: 0 0 30px 0;
	color:$color-primary-01;
	font-weight:300;


}

h1{

	font-size:2.2rem;
	font-weight: 400;

	@include respond-to(small){
			font-size:2.8rem;
	}

}

h3{

	font-size:1.3rem;
	font-weight: 400;

	@include respond-to(small){
			font-size:1.5rem;
	}

}


p{

	padding:0;
	margin: 0 0 15px 0;

}


a{

	text-decoration: none;
	color:$color-primary-01;

	&:hover{

		text-decoration: underline;
	}

}

// ...



.heading-3{

	font-size:1.4rem;
	font-weight: 300;
	//text-transform: uppercase;
	display: block;
	margin-bottom: $base-unit;

	@include respond-to(small){
			font-size:1.8rem;
			margin-bottom: ($base-unit*1.2);
	}

}
