

.message, .alert{

  padding:20px;
  width:100%;
  margin: 0 0 30px 0;
  background: #fcf8e3;
  color:#222;
  border:1px solid #e5d99c;
  font-size: 1.5rem;
  color:$color-primary-01;
  border-radius:3px;


  &--global{
    margin-bottom:0;
   	text-align: center;
  }


  &--succes{
  	color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;

  }

    &--warning{
 		background: #fcf8e3;
		color:#222;
		border:1px solid #e5d99c;

  }

}
.displayflex {
  display:flex;
  align-items: center;
  padding: 25px !important;
}
