.messages-bar{

  height: 100%;
  position: fixed;
  top:0;
  z-index: 999;
  visibility: hidden;
  transition: visibility 150ms ease-in;


  &--active{

    visibility: visible;
    opacity: 1;

    .messages-bar__transparant{
        opacity: 1;
    }
    .messages-bar__inner{
        opacity: 1;
        transform: translateX(0%);
    }

    .messages-bar__close{
        opacity: 0.5;
        transform: translateY(0);
    }

  }


  &__close{

    position: fixed;;
    right: 15px;
    top:15px;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    display: block;
    z-index: 3;
    overflow: hidden;
    text-indent: -5000em;
    background-position:center center;
    background-repeat:no-repeat;
    background-image:url(../img/ico-close-black.png);
    background-size:14px auto;
    opacity: 0;

    transition: opacity 100ms ease-in 500ms;
  

    &:hover{
      transition: opacity 150ms ease-in;
      opacity: 1;
      cursor: hand;
      cursor: pointer;
    }
  }

  &__inner{

    position: fixed;
    z-index: 1000;
    top:0;
    right:0;
    background-color: #fff;
    height: 100%;
    overflow: hidden;
    overflow-y: visible;
    z-index: 2;
    padding: 30px;
    max-width:420px;
    width: 90%;
    transform: translateX(100%);
    transition: transform 150ms ease-in 250ms;

  }

  &__transparant{

    background-color: rgba(0,0,0,0.5);
    left:0;
    top:0;
    right:0;
    bottom:0;
    position: fixed;
    z-index: 1;

    opacity: 0;
    transition: opacity 150ms ease-in;
  }


  &__title{
    display: block;
    margin-bottom: 45px;
    font-weight: 400;
    color:#000;
    font-size: 1.2rem;

  }


  &__item{

    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #dedede;
    //padding-left: 65px;
    position: relative;
    width: calc(100% - 65px);
    margin-left: 65px;

    &:last-child{
      border-bottom: none;
    }

    span{
      display: block;
      //margin-bottom: 10px;
    }

    &__icon{
      display: block;
      position: absolute;
      left:-65px;
      top:0;
      width: 45px;
      height: 26px;
      //background-color:#f5f5f5;
      border-radius: 100%;
      background-position:center center;
      background-repeat:no-repeat;
      background-image: url(../img/ico-notification-black.png);
      background-size:22px auto;
      opacity: 0.5;
    }

    &__title{
      font-weight: 400;
      color:$color-primary-01;
      margin-bottom: 15px;
      font-size: 0.9rem;
    }


    &__message{
      font-size: 0.9rem;
      line-height: 1.4rem;
      margin-bottom: 15px;
      color: $color-gray-01;
    }

    &__time{
        font-size: 0.9rem;
        margin-bottom: 0px;
        color:$color-primary-01;
        font-weight: 400;
    }


  }


}
