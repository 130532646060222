/* ----------------------------------------------------------------------
 *
 * IMPORTS
 *
 -----------------------------------------------------------------------*/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* ----------------------------------------------------------------------
 *
 * BREAKPOINTS
 *
 -----------------------------------------------------------------------*/
/* ----------------------------------------------------------------------
 *
 * HELPERS
 *
 -----------------------------------------------------------------------*/
.clear {
  clear: both; }

.pull-to-center {
  float: none !important;
  margin: 0 auto; }

.pull-to-left {
  float: right !important; }

@media (min-width: 767px) {
  .pull-to-right {
    float: right !important; } }

.kleur-red {
  color: red !important; }

.kleur-green {
  color: green; }

.formvalidationerror {
  padding: 10px;
  background-color: #f44336;
  /* Red */
  color: white;
  width: 100%;
  font-weight: 500; }

.tooltip {
  font-size: 14px; }

.disabled {
  opacity: 0.6;
  color: red !important; }

/* ----------------------------------------------------------------------
 *
 * GRID SYSTEM
 *
 -----------------------------------------------------------------------*/
.wrap {
  max-width: 1940px;
  margin: 0 auto;
  position: relative; }

[class*='col'] {
  padding: 0 15px;
  width: 100%;
  min-height: 1px;
  margin-bottom: 30px;
  position: relative; }
  @media (min-width: 767px) {
    [class*='col'] {
      padding: 0 30px; } }

.col-hide {
  display: none; }

@media (min-width: 767px) {
  .wrap:after, .row:after {
    content: "";
    display: table;
    clear: both; }
  [class*='col-s'] {
    float: left;
    margin-bottom: 0;
    display: block; }
  .col-s-1 {
    width: 8.33333%; }
  .col-s-2 {
    width: 16.66667%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33333%; }
  .col-s-5 {
    width: 41.66667%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33333%; }
  .col-s-8 {
    width: 66.66667%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33333%; }
  .col-s-11 {
    width: 91.66667%; }
  .col-s-12 {
    width: 100%; } }

@media (min-width: 992px) {
  .wrap:after, .row:after {
    content: "";
    display: table;
    clear: both; }
  [class*='col-m'] {
    float: left;
    margin-bottom: 0;
    display: block; }
  .col-m-1 {
    width: 8.33333%; }
  .col-m-2 {
    width: 16.66667%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33333%; }
  .col-m-5 {
    width: 41.66667%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33333%; }
  .col-m-8 {
    width: 66.66667%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33333%; }
  .col-m-11 {
    width: 91.66667%; }
  .col-m-12 {
    width: 100%; } }

@media (min-width: 1280px) {
  .wrap:after, .row:after {
    content: "";
    display: table;
    clear: both; }
  [class*='col-l'] {
    float: left;
    margin-bottom: 0;
    display: block; }
  .col-l-1 {
    width: 8.33333%; }
  .col-l-2 {
    width: 16.66667%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33333%; }
  .col-l-5 {
    width: 41.66667%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33333%; }
  .col-l-8 {
    width: 66.66667%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33333%; }
  .col-l-11 {
    width: 91.66667%; }
  .col-l-12 {
    width: 100%; } }

.table-responsive {
  /* IE10+ CSS styles go here */
  overflow: auto; }

*, *:after, *:before {
  box-sizing: border-box; }

span {
  font-size: 14px; }

html {
  font-size: 14px;
  height: 100%;
  background-color: #F5F5F5; }

body {
  font-family: "Raleway", sans-serif;
  color: #162133;
  background-color: #F5F5F5;
  font-weight: 300;
  overflow-x: hidden;
  height: 100%; }

h1, h2, h3, h4, h5 {
  padding: 0;
  margin: 0 0 30px 0;
  color: #162133;
  font-weight: 300; }

h1 {
  font-size: 2.2rem;
  font-weight: 400; }
  @media (min-width: 767px) {
    h1 {
      font-size: 2.8rem; } }

h3 {
  font-size: 1.3rem;
  font-weight: 400; }
  @media (min-width: 767px) {
    h3 {
      font-size: 1.5rem; } }

p {
  padding: 0;
  margin: 0 0 15px 0; }

a {
  text-decoration: none;
  color: #162133; }
  a:hover {
    text-decoration: underline; }

.heading-3 {
  font-size: 1.4rem;
  font-weight: 300;
  display: block;
  margin-bottom: 30px; }
  @media (min-width: 767px) {
    .heading-3 {
      font-size: 1.8rem;
      margin-bottom: 36px; } }

/* ----------------------------------------------------------------------
 *
 * LAYOUT
 *
 -----------------------------------------------------------------------*/
.logo-peugeot {
  width: 158px;
  height: 111px;
  display: block;
  background-image: url("../img/logo-peugeot-dark.svg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 158px auto; }
  .logo-peugeot--white {
    background-image: url("../img/logo-peugeot-white.svg"); }

.page-wrap {
  margin-top: 80px; }

.header {
  position: fixed;
  z-index: 888;
  top: 0;
  right: 0;
  width: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid #dedede;
  height: 85px; }
  @media (min-width: 767px) {
    .header {
      margin: 0 0 30px; } }
  .header .wrap {
    height: 100%;
    position: relative; }
  .header__page-title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #162133;
    font-weight: 800;
    font-size: 2rem; }
  .header .nav-wrap {
    position: absolute;
    z-index: 999;
    top: 50%;
    right: 35px;
    transform: translateY(-50%);
    display: none; }
    @media (min-width: 767px) {
      .header .nav-wrap {
        display: block; } }
  .header .global-nav,
  .header .global-nav li,
  .header .global-nav ul,
  .header .util-nav,
  .header .util-nav li,
  .header .util-nav ul {
    float: left;
    display: inline-block; }
  .header .global-nav li.active a,
  .header .util-nav li.active a {
    color: #ffffff; }
  .header .global-nav a,
  .header .util-nav a {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.5);
    transition: color 200ms ease-in;
    height: 32px;
    line-height: 32px; }
    .header .global-nav a:hover,
    .header .util-nav a:hover {
      text-decoration: none;
      color: #ffffff; }
  .header .util-nav li:hover .util-nav__dropdown {
    display: block; }
  .header .util-nav__notifications .util-nav__dropdown {
    width: 320px; }
  .header .util-nav__dropdown {
    display: none;
    position: absolute;
    right: -2px;
    top: 30px;
    width: 200px;
    background-color: #fff;
    border: 1px solid #dedede;
    border-radius: 3px;
    padding: 8px;
    /*
      &:before{

         content:'';
         display: block;
         width: 0;
         height: 0;
         border-left: 8px solid transparent;
         border-right: 8px solid transparent;
         border-bottom: 8px solid #fff;
         position: absolute;
         top:-7px;
         right:8px;

      }*/ }
    .header .util-nav__dropdown a,
    .header .util-nav__dropdown li,
    .header .util-nav__dropdown ul {
      display: block;
      float: none;
      width: 100%;
      margin: 0;
      padding: 0; }
    .header .util-nav__dropdown a {
      width: 100% !important;
      background-color: #fff !important;
      color: #162133 !important;
      overflow: visible !important;
      text-indent: 0 !important;
      padding: 0 15px;
      transition: padding 200ms ease-in;
      font-size: 1.3rem; }
  .header .util-nav ul {
    margin: 0; }
  .header .util-nav li {
    position: relative; }
  .header .util-nav a,
  .header .util-nav span {
    width: 32px;
    display: block;
    overflow: hidden;
    text-indent: -5000em;
    background-position: center center;
    background-repeat: no-repeat;
    text-decoration: none;
    opacity: 0.6;
    transition: opacity 200ms ease-in; }
    .header .util-nav a:hover,
    .header .util-nav span:hover {
      opacity: 1;
      cursor: hand;
      cursor: pointer; }
  .header .util-nav__notifications--new:after {
    content: "";
    display: block;
    position: absolute;
    right: -7px;
    top: -7px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: #409EE4; }
  .header .util-nav__notifications a,
  .header .util-nav__notifications span {
    background-image: url("../img/ico-notification-black.png");
    background-size: 23px auto; }
  .header .util-nav__myaccount > a {
    background-image: url("../img/ico-user-black-line.png");
    background-size: 24px auto; }
  .header .util-nav__manual > a {
    background-image: url("../img/ico-pdf-black.png");
    background-size: 24px auto;
    width: 30px; }

/* ----------------------------------------------------------------------
 *
 * NOTIFICATIONS
 *
 -----------------------------------------------------------------------*/
.notifications__item {
  margin: 0;
  padding: 15px 30px;
  font-size: 0.9rem; }

/* ----------------------------------------------------------------------
 *
 * layout
 *
 -----------------------------------------------------------------------*/
.layout {
  background-color: #F5F5F5;
  padding: 30px 10px; }
  @media (min-width: 1280px) {
    .layout {
      padding: 30px 60px; } }

/* ----------------------------------------------------------------------
 *
 * global-nav
 *
 -----------------------------------------------------------------------*/
.toggle-nav {
  display: block;
  width: 40px;
  height: 45px;
  cursor: pointer;
  cursor: hand;
  position: absolute;
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  background-image: url("../img/ico-hamburger.png");
  background-size: 24px auto;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.6;
  transition: opacity 200ms ease-in; }

.global-nav .form__select {
  margin-left: 30px;
  margin-bottom: 30px;
  width: 80%; }

.global-nav__transparent {
  display: block;
  content: '';
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 990;
  display: none; }
  .global-nav__transparent:hover {
    cursor: pointer;
    cursor: hand; }

.global-nav__inner {
  background: #162133;
  height: 100%;
  position: fixed;
  transform: translate(-100%, 0);
  bottom: 0;
  top: 0;
  z-index: 999;
  overflow: hidden;
  overflow-y: auto;
  width: 90%; }
  @media (min-width: 767px) {
    .global-nav__inner {
      width: 340px; } }

.global-nav .button {
  width: 90%;
  display: block;
  margin: 30px auto; }
  @media (min-width: 767px) {
    .global-nav .button {
      display: none; } }

.global-nav__logo {
  margin: 0 auto;
  text-align: center;
  width: 100%;
  margin: 30px 0;
  /*
     @include respond-to(small){
       position: absolute;
       bottom:$base-unit;
       left:50%;
      transform: translateX(-50%);
    }*/ }
  .global-nav__logo .logo-peugeot {
    display: inline-block;
    margin-bottom: 20px;
    background-size: 120px auto;
    width: 120px;
    height: 86px; }
  .global-nav__logo span {
    display: block;
    color: #fff;
    font-size: 0.9rem;
    opacity: 1; }

.global-nav__menu {
  margin-bottom: 30px; }
  .global-nav__menu__more {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 60px;
    background-image: url("../img/ico-plus-white.png");
    background-size: 14px auto;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.6;
    transition: opacity 150ms ease-in; }
    .global-nav__menu__more:hover {
      opacity: 1;
      cursor: pointer;
      cursor: hand; }
  .global-nav__menu ul li.active .global-nav__menu__more {
    background-image: url("../img/ico-min-white.png"); }
  .global-nav__menu ul li.active ul {
    display: block; }
  .global-nav__menu ul li {
    position: relative;
    border-bottom: 1px solid rgba(255, 255, 255, 0.09);
    padding-right: 60px; }
    .global-nav__menu ul li a {
      display: block;
      text-decoration: none;
      font-size: 1rem;
      color: #ffffff;
      text-transform: uppercase;
      font-weight: 400;
      padding: 22px 30px;
      transition: padding 150ms ease-in;
      position: relative;
      border-left: 5px solid #162133; }
      .global-nav__menu ul li a:hover {
        text-decoration: none;
        cursor: hand;
        cursor: pointer; }
      .global-nav__menu ul li a .counter {
        background-color: #0393CF;
        width: 28px;
        height: 28px;
        border-radius: 100%;
        font-size: 0.8rem;
        display: inline-block;
        text-align: center;
        line-height: 28px;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%); }
    .global-nav__menu ul li ul {
      padding-bottom: 20px; }
      .global-nav__menu ul li ul li {
        border-bottom: none; }
        .global-nav__menu ul li ul li.active a {
          border-left: 5px solid #409EE4; }
        .global-nav__menu ul li ul li.active > a > span {
          opacity: 1; }
        .global-nav__menu ul li ul li a {
          font-weight: 300;
          padding-top: 10px;
          padding-bottom: 10px; }
          .global-nav__menu ul li ul li a span {
            font-size: 1.2rem;
            opacity: 0.6;
            font-weight: 400; }

html.html--show-nav .header {
  width: inherit; }

html.html--show-nav .page-wrap {
  width: 100%;
  height: 100%; }
  @media (min-width: 767px) {
    html.html--show-nav .page-wrap {
      transform: none;
      width: calc(100% - 340px);
      float: right; } }

html.html--show-nav .global-nav__transparent {
  display: block; }
  @media (min-width: 767px) {
    html.html--show-nav .global-nav__transparent {
      display: none !important; } }

html.html--show-nav .global-nav__inner {
  transform: translate(0, 0); }

.list-actions {
  position: sticky;
  top: 85px;
  background-color: #FFF;
  padding: 25px;
  margin-bottom: 30px; }

#authKey, #apiurl {
  display: none; }

/* ----------------------------------------------------------------------
*
* FORM
*
-----------------------------------------------------------------------*/
.validation {
  font-size: 0.8rem;
  color: red;
  margin-top: 8px; }

.ng-submitted .form__input .ng-invalid {
  border: 1px solid red !important; }

.form__group {
  margin-bottom: 30px; }

.form__file {
  margin-bottom: 30px; }
  .form__file .button {
    margin-bottom: 15px; }
  .form__file__progress {
    margin-bottom: 15px; }
    .form__file__progress:after {
      content: "";
      display: table;
      clear: both; }
    .form__file__progress__bar {
      float: left;
      width: 300px;
      height: 15px;
      border: 1px solid #dedede;
      margin-right: 15px; }
      .form__file__progress__bar span {
        display: block;
        background-color: #409EE4;
        height: 100%; }
    .form__file__progress__percentage {
      float: left; }
    .form__file__progress__annotation {
      font-size: 0.9rem; }

.form__multiselect input[type="checkbox"] {
  display: none; }

.form__multiselect label:hover {
  cursor: pointer;
  cursor: hand; }

.form__multiselect__checkbox {
  border: 1px solid #ccc;
  background-color: #fff;
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 2px;
  margin-right: 8px;
  position: relative; }
  .form__multiselect__checkbox:after {
    width: 9px;
    height: 9px;
    content: '';
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #409EE4; }
  .form__multiselect__checkbox--active:after {
    display: block; }

.form__multiselect__label {
  height: 18px;
  display: inline-block;
  line-height: 16px; }

.form__select {
  overflow: hidden;
  position: relative;
  border: 1px solid #dedede;
  width: 100%;
  border-radius: 3px;
  background-color: #fff;
  margin-bottom: 10px; }
  .form__select:hover {
    cursor: hand;
    cursor: pointer; }
    .form__select:hover:after {
      opacity: 1; }
  .form__select:after {
    content: '';
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("../img/ico-arrow-down-black.png");
    background-position: 96% 49%;
    background-repeat: no-repeat;
    background-size: 11px auto;
    opacity: 0.6;
    transition: opacity 150ms ease-in; }
  @media (min-width: 767px) {
    .form__select {
      margin-bottom: 0; } }
  .form__select select {
    box-sizing: border-box;
    padding: 12px 15px;
    width: 100%;
    font-size: 1.3rem;
    font-weight: 300;
    font-family: "Raleway", sans-serif;
    border: none;
    box-shadow: none;
    background: transparent;
    background-image: none;
    -webkit-appearance: none; }
    .form__select select:hover {
      cursor: hand;
      cursor: pointer; }
    .form__select select:focus {
      outline: none; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form__select:after {
    content: none;
    display: none;
    width: 0px; } }

@media (min-width: 767px) {
  .form__select.datalist-utils__sort {
    margin-right: 10px; } }

.form__input {
  margin: 0 0 30px;
  position: relative; }
  .form__input__add:after {
    content: "";
    display: table;
    clear: both; }
  .form__input__add .form__select {
    width: calc(100% - 60px);
    float: left; }
  .form__input__add__button {
    float: right;
    display: block;
    width: 43px;
    height: 43px;
    background-color: #f5f5f5;
    line-height: 43px;
    text-align: center;
    border-radius: 100%;
    transition: all 150ms ease-in;
    font-size: 1.1rem;
    text-indent: -5000em;
    overflow: hidden;
    background-image: url("../img/ico-plus-black.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 11px auto; }
    .form__input__add__button:hover {
      cursor: pointer;
      cursor: hand;
      background-color: #409EE4;
      color: #fff;
      background-image: url("../img/ico-plus-white.png"); }
  .form__input label {
    display: block;
    color: #162133;
    margin: 0 0 15px;
    text-transform: uppercase; }
  .form__input__disabled {
    display: block; }
  .form__input input[type='password'],
  .form__input input[type='text'],
  .form__input input[type='time'],
  .form__input input[type='number'],
  .form__input textarea {
    border: 1px solid gainsboro;
    padding: 12px 15px;
    width: 100%;
    font-size: 1.5rem;
    font-weight: 300;
    font-family: "Raleway", sans-serif;
    background-color: #F5F5F5;
    transition: border 200ms ease-in; }
    .form__input input[type='password'].datepicker,
    .form__input input[type='text'].datepicker,
    .form__input input[type='time'].datepicker,
    .form__input input[type='number'].datepicker,
    .form__input textarea.datepicker {
      background-image: url("../img/ico-date-black.png");
      background-position: 97% 49%;
      background-repeat: no-repeat;
      background-size: 18px auto; }
    .form__input input[type='password']:focus,
    .form__input input[type='text']:focus,
    .form__input input[type='time']:focus,
    .form__input input[type='number']:focus,
    .form__input textarea:focus {
      border: 1px solid #162133;
      outline: none; }
  .form__input textarea {
    height: 360px; }

.date_group .entypo-calendar {
  display: inline; }

.form__footer {
  padding: 30px 0;
  text-align: right; }
  .form__footer .button {
    margin-bottom: 15px; }
    @media (min-width: 767px) {
      .form__footer .button {
        margin-left: 15px; } }

.after_form {
  margin-top: 15px;
  float: left; }

/* ----------------------------------------------------------------------
 *
 * BUTTON
 *
 -----------------------------------------------------------------------*/
.button {
  display: inline-block;
  background-color: #409EE4;
  color: #ffffff;
  padding: 0 30px;
  border: none;
  height: 45px;
  line-height: 45px;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  width: 100%; }
  @media (min-width: 767px) {
    .button {
      width: 220px; } }
  .button:hover {
    transition: all 200ms ease-in;
    text-decoration: none;
    cursor: hand;
    cursor: pointer;
    background-color: #1e86d3; }
  .button--white {
    border: 1px solid rgba(255, 255, 255, 0.5);
    color: #fff;
    background: transparent; }
    .button--white:hover {
      background-color: #409EE4;
      border: 1px solid #409EE4;
      color: #fff; }
  .button--secondary {
    border: 1px solid #162133;
    color: #162133;
    background: transparent; }
    .button--secondary:hover {
      background-color: #409EE4;
      border: 1px solid #409EE4;
      color: #fff; }
  .button--full {
    width: 100%; }
  .button--small {
    font-size: 0.8rem;
    height: 40px;
    line-height: 40px; }

.marginauto {
  margin-left: auto; }

.btn-red {
  background-color: red; }
  .btn-red:hover {
    background-color: darkred; }

.btn-green {
  background-color: #5cb85c; }
  .btn-green:hover {
    background-color: #3d8b3d; }

.btn-orange {
  background-color: #FF7F50; }
  .btn-orange:hover {
    background-color: #FF4500; }

.uib-day .btn-default[disabled] {
  opacity: 0.2 !important;
  color: black; }

/* ----------------------------------------------------------------------
 *
 * POPUP
 *
 -----------------------------------------------------------------------*/
.popup {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden; }
  .popup--no-padding .popup__inner {
    padding: 0; }
  @media (min-width: 767px) {
    .popup--small .popup__window {
      width: 800px;
      height: 300px; } }
  .popup__window {
    background: #fff;
    position: absolute;
    left: 50%;
    margin-bottom: 30px;
    width: 90%;
    border-radius: 5px; }
  .popup--open {
    background: rgba(0, 0, 0, 0.5);
    transition: background 200ms ease-in;
    visibility: visible;
    overflow-y: visible; }
    .popup--open .popup__window {
      transform: translate(-50%, 30px);
      transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1) 200ms; }
  .popup--close {
    background: transparent;
    transition: background 200ms ease-in 200ms;
    visibility: hidden; }
    .popup--close .popup__window {
      transform: translate(-50%, 400%);
      transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1); }
  .popup__header {
    border-bottom: 1px solid #dedede;
    padding: 20px 30px;
    position: relative;
    text-transform: uppercase;
    color: #F5F5F5;
    font-size: 0.9rem;
    font-weight: 400;
    background-color: #F3F0EB;
    border-radius: 5px 5px 0 0; }
  .popup__inner {
    padding: 30px; }
  .popup__close {
    background: none;
    border: none;
    display: block;
    width: 32px;
    height: 32px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    overflow: hidden;
    text-indent: -5000em;
    opacity: 0.4;
    transition: opacity 150ms ease-in;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../img/ico-close-black.png);
    background-size: 14px auto; }
    .popup__close:focus {
      outline: none; }
    .popup__close:hover {
      opacity: 1; }

.loading-screen {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
  display: none; }
  .loading-screen .loader-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%); }
  .loading-screen--show {
    display: block; }

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em; }

.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear; }

@-webkit-keyframes load8 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes load8 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.message, .alert {
  padding: 20px;
  width: 100%;
  margin: 0 0 30px 0;
  background: #fcf8e3;
  color: #222;
  border: 1px solid #e5d99c;
  font-size: 1.5rem;
  color: #162133;
  border-radius: 3px; }
  .message--global, .alert--global {
    margin-bottom: 0;
    text-align: center; }
  .message--succes, .alert--succes {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6; }
  .message--warning, .alert--warning {
    background: #fcf8e3;
    color: #222;
    border: 1px solid #e5d99c; }

.displayflex {
  display: flex;
  align-items: center;
  padding: 25px !important; }

/* ----------------------------------------------------------------------
 *
 * PRODUCT-DETAIL
 *
 -----------------------------------------------------------------------*/
.product-detail {
  line-height: 1.6rem; }
  @media (min-width: 767px) {
    .product-detail:after {
      content: "";
      display: table;
      clear: both; }
    .product-detail__image, .product-detail__info {
      float: left;
      width: 50%; }
    .product-detail__info {
      padding-left: 60px; } }
  .product-detail__title {
    margin-bottom: 10px; }
  .product-detail__price {
    margin-bottom: 30px;
    font-size: 1.4rem;
    color: #162133; }
  .product-detail__benefits {
    margin-bottom: 30px; }
    .product-detail__benefits li {
      margin: 0 0 10px 0;
      padding-left: 33px;
      background-position: center left;
      background-repeat: no-repeat;
      background-image: url(../img/ico-check-black.png);
      background-size: 18px auto; }
  .product-detail__description {
    margin-bottom: 30px; }

/* ----------------------------------------------------------------------
 *
 * TIMELINE
 *
 -----------------------------------------------------------------------*/
.timeline {
  margin-top: 45px;
  padding-right: 15px; }
  .timeline__messages {
    margin-top: 20px; }
    .timeline__messages__item {
      margin-bottom: 15px; }
      .timeline__messages__item h4 {
        margin-bottom: 5px;
        font-size: 0.9rem;
        font-weight: 400; }
      .timeline__messages__item p {
        margin-bottom: 5px;
        font-size: 0.9rem; }
  .timeline__item {
    border-left: 3px solid #f5f5f5;
    margin-left: 15px;
    padding-bottom: 30px;
    position: relative; }
    .timeline__item__marker {
      border-radius: 100%;
      background-color: #ccc;
      width: 14px;
      height: 14px;
      display: block;
      position: absolute;
      left: -8px;
      top: 15px;
      border: 3px solid #fff; }
    .timeline__item__inner {
      margin-left: 41px; }
    .timeline__item__title {
      border-radius: 3px 3px 0 0;
      padding: 10px 30px;
      background: #fff;
      border: 1px solid #dedede;
      color: #162133;
      position: relative; }
      .timeline__item__title:hover {
        cursor: hand;
        cursor: pointer; }
      .timeline__item__title:after {
        content: " ";
        display: block;
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -17px;
        width: 32px;
        height: 32px;
        overflow: hidden;
        text-indent: -5000em;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url(../img/ico-plus-black.png);
        background-size: 14px auto;
        opacity: 0.3; }
    .timeline__item__content {
      border-radius: 0 0 3px 3px;
      padding: 30px 30px;
      background: #fff;
      border: 1px solid #dedede;
      border-top: none;
      display: none; }
    .timeline__item--active .timeline__item__title {
      background: #409EE4;
      color: #ffffff;
      border: 1px solid #409EE4; }
      .timeline__item--active .timeline__item__title:after {
        background-image: url(../img/ico-plus-white.png);
        opacity: 1; }
    .timeline__item--active .timeline__item__marker {
      border-radius: 100%;
      background-color: #fff;
      width: 16px;
      height: 16px;
      display: block;
      position: absolute;
      left: -9px;
      top: 15px;
      border: 3px solid #409EE4; }
    .timeline__item--open .timeline__item__title:after {
      background-image: url(../img/ico-min-black.png);
      opacity: 1; }
    .timeline__item--open .timeline__item__content {
      display: block; }

/* ----------------------------------------------------------------------
 *
 * PANEL
 *
 -----------------------------------------------------------------------*/
.panel {
  border: 1px solid #dedede;
  background-color: #ffffff;
  margin: 0 0 30px;
  border-radius: 0;
  box-shadow: none; }
  .panel__inner {
    padding: 40px;
    line-height: 1.6rem; }
    .panel__inner--no-padding {
      padding: 0; }
  .panel__title {
    padding: 20px 30px;
    margin-bottom: 0;
    border-bottom: 1px solid #dedede;
    background-color: #fff;
    font-size: 1rem;
    font-weight: 400;
    color: #162133;
    position: relative;
    border-radius: 0;
    text-transform: uppercase; }
    .panel__title:after {
      content: " ";
      display: block;
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -17px;
      width: 32px;
      height: 32px;
      overflow: hidden;
      text-indent: -5000em;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url("../img/ico-min-black.png");
      background-size: 14px auto;
      opacity: 0.3; }
  .panel--closed .panel__title {
    border-bottom: none; }
    .panel--closed .panel__title:after {
      background-image: url("../img/ico-plus-black.png"); }
    .panel--closed .panel__title:hover {
      cursor: hand;
      cursor: pointer; }
  .panel--closed .panel__inner {
    display: none; }

.panel__flex .panel__inner {
  padding: 20px;
  line-height: 1.6rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }
  @media (max-width: 1024px) {
    .panel__flex .panel__inner {
      flex-direction: column; } }
  .panel__flex .panel__inner .sort_group {
    display: flex;
    margin-bottom: 20px;
    width: auto;
    justify-content: space-between; }
    @media (max-width: 1024px) {
      .panel__flex .panel__inner .sort_group {
        width: 100%;
        flex-direction: row;
        justify-content: flex-start; } }
    .panel__flex .panel__inner .sort_group .input-group-addon {
      display: flex;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      min-height: 30px; }
    .panel__flex .panel__inner .sort_group select {
      background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      /* and then whatever styles you want*/
      height: 30px;
      min-width: 200px;
      width: 100%;
      padding: 5px;
      border: 1px solid #CCC; }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .panel__flex .panel__inner .sort_group select {
          background: none; } }
  .panel__flex .panel__inner .location_group {
    display: flex;
    margin-bottom: 20px;
    width: auto;
    justify-content: space-between;
    width: 20%;
    min-width: 215px; }
    @media (max-width: 1024px) {
      .panel__flex .panel__inner .location_group {
        width: 100%;
        flex-direction: row;
        justify-content: flex-start; } }
    @media (max-width: 1024px) {
      .panel__flex .panel__inner .location_group {
        width: 100%; } }
  .panel__flex .panel__inner .date_group {
    display: flex;
    margin-bottom: 20px;
    width: auto;
    justify-content: space-between;
    position: relative;
    width: 20%;
    min-width: 215px; }
    @media (max-width: 1024px) {
      .panel__flex .panel__inner .date_group {
        width: 100%;
        flex-direction: row;
        justify-content: flex-start; } }
    @media (max-width: 1024px) {
      .panel__flex .panel__inner .date_group {
        width: 100%; } }
  .panel__flex .panel__inner .button {
    margin-top: -6px; }
    @media (max-width: 1024px) {
      .panel__flex .panel__inner .button {
        margin-top: 0; } }

.entypo-calendar {
  width: 25px;
  display: block;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  position: absolute;
  top: 3px; }

.entypo-calendar:after {
  content: url(../img/calendar.svg);
  background-size: 60%;
  width: 20px;
  height: 20px;
  opacity: 0.3;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease; }

.date_group:hover .entypo-calendar:after {
  opacity: 1;
  transition: opacity .3s ease; }

input#daterange3 {
  padding-left: 30px;
  height: 30px; }

.datalist {
  border: 1px solid #dedede;
  margin-bottom: 45px;
  background: #fff; }
  .datalist .bluetooth {
    cursor: pointer; }
  .datalist__group-header {
    background-color: #fff;
    text-align: left;
    padding: 20px;
    border-bottom: 1px solid #dedede;
    font-size: 1.2rem;
    color: #162133;
    background-color: #fff; }
  @media (min-width: 767px) {
    .datalist--toggle-active .datalist__item {
      padding-left: 90px; } }
  .datalist__container {
    overflow: hidden;
    overflow-y: scroll; }
  .datalist__toggle {
    height: 45px;
    width: 45px;
    line-height: 45px;
    position: absolute;
    left: 15px;
    top: 15px;
    overflow: hidden;
    text-indent: -5000em;
    display: none;
    background-size: 14px auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("../img/ico-plus.png");
    opacity: 0.3;
    transition: opacity 150ms ease-in; }
    @media (min-width: 767px) {
      .datalist__toggle {
        display: block; } }
    .datalist__toggle:hover {
      opacity: 1;
      cursor: hand;
      cursor: pointer; }
  .datalist__cells {
    width: 100%;
    display: block;
    margin-bottom: 0; }
    @media (min-width: 767px) {
      .datalist__cells {
        display: table;
        margin-bottom: 0; } }
    .datalist__cells .datalist__cell {
      display: block;
      line-height: 1.4; }
      .datalist__cells .datalist__cell--strong {
        font-weight: 400;
        color: #162133; }
      @media (min-width: 767px) {
        .datalist__cells .datalist__cell {
          display: table-cell;
          vertical-align: middle;
          width: 20%;
          height: 75px; }
          .datalist__cells .datalist__cell--strong {
            font-weight: 600; }
          .datalist__cells .datalist__cell--thumb {
            width: 8%; } }
  .datalist__item {
    padding: 15px 10px;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #dedede;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .datalist__item:after {
      content: "";
      display: table;
      clear: both; }
    @media (min-width: 767px) {
      .datalist__item {
        padding: 15px 20px; } }
    .datalist__item__left {
      width: calc(100% - 70px);
      margin-right: 20px; }
      @media (min-width: 767px) {
        .datalist__item__left {
          width: 70%;
          float: left; } }
    .datalist__item__right {
      width: 50px; }
      @media (min-width: 767px) {
        .datalist__item__right {
          width: 20%;
          float: left;
          text-align: right; } }
    .datalist__item--headings {
      display: none;
      padding-top: 10px;
      padding-bottom: 10px; }
      @media (min-width: 767px) {
        .datalist__item--headings {
          display: block; } }
      .datalist__item--headings .datalist__cell {
        color: #162133;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 1.2rem; }
    .datalist__item:last-child {
      border-bottom: none; }
    .datalist__item--odd {
      background-color: #FBFBFB; }
    .datalist__item--selected {
      border: 1px solid #F5F5F5; }
    .datalist__item--active .datalist__item__quickview {
      display: block; }
    .datalist__item__quickview {
      display: none;
      padding: 15px 0 30px; }
      .datalist__item__quickview:after {
        content: "";
        display: table;
        clear: both; }
      .datalist__item__quickview__item {
        float: left;
        width: 50%;
        margin-bottom: 10px; }
        .datalist__item__quickview__item:after {
          content: "";
          display: table;
          clear: both; }
        .datalist__item__quickview__item span {
          display: block;
          width: 50%;
          float: left;
          padding: 0; }
  .datalist__no-records {
    padding: 30px;
    text-align: center;
    border-bottom: 1px solid #dedede; }
  .datalist .datalist__center {
    text-align: center; }
  .datalist .datalist__description {
    font-size: 1rem;
    color: #162133;
    display: block;
    margin-bottom: 10px;
    font-weight: 400; }
  .datalist__meta {
    color: #F5F5F5;
    display: block; }
  .datalist__imported {
    display: inline-block;
    width: 45px;
    height: 45px;
    text-indent: -5000em;
    overflow: hidden;
    background-size: 18px auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("../img/ico-imported.png");
    opacity: 0.3;
    transition: opacity 150ms ease-in; }
    .datalist__imported:hover {
      opacity: 1; }
  .datalist__actions,
  .datalist__actions a,
  .datalist__actions li {
    display: inline-block;
    margin: 0; }
  .datalist__actions span {
    display: none; }
  .datalist__actions li {
    border: 1px solid #dedede;
    border-radius: 3px; }
    @media (min-width: 767px) {
      .datalist__actions li {
        border: none;
        border-radius: 0; } }
  .datalist__actions__move span {
    display: inline-block;
    width: 45px;
    height: 45px;
    overflow: hidden;
    background-size: 19px auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("../img/ico-list-order.png");
    opacity: 0.3;
    transition: opacity 150ms ease-in;
    cursor: move; }
    .datalist__actions__move span:hover {
      opacity: 1;
      cursor: move; }
  .datalist__actions__duplicate a {
    display: inline-block;
    width: 45px;
    height: 45px;
    overflow: hidden;
    background-size: 19px auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("../img/ico-duplicate-black.png");
    opacity: 0.3;
    transition: opacity 150ms ease-in; }
    .datalist__actions__duplicate a:hover {
      opacity: 1; }
  .datalist__actions__delete a {
    display: inline-block;
    width: 45px;
    height: 45px;
    overflow: hidden;
    background-size: 22px auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("../img/ico-delete-red.png");
    opacity: 0.3;
    transition: opacity 150ms ease-in; }
    .datalist__actions__delete a:hover {
      opacity: 1; }
  .datalist__actions__edit a {
    display: inline-block;
    width: 45px;
    height: 45px;
    overflow: hidden;
    background-size: 18px auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("../img/ico-edit.png");
    opacity: 0.3;
    transition: opacity 150ms ease-in; }
    .datalist__actions__edit a:hover {
      opacity: 1; }
  .datalist__actions__view a {
    display: inline-block;
    width: 45px;
    height: 45px;
    overflow: hidden;
    background-size: 18px auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("../img/ico-view.png");
    opacity: 0.3;
    transition: opacity 150ms ease-in; }
    .datalist__actions__view a:hover {
      opacity: 1; }
  .datalist__actions__user a {
    display: inline-block;
    width: 45px;
    height: 45px;
    overflow: hidden;
    background-size: 18px auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("../img/ico-user-black.png");
    opacity: 0.3;
    transition: opacity 150ms ease-in; }
    .datalist__actions__user a:hover {
      opacity: 1; }
  .datalist__actions__info a {
    display: inline-block;
    width: 45px;
    height: 45px;
    overflow: hidden;
    background-size: 18px auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("../img/ico-info.png");
    position: absolute;
    right: 30px;
    top: 25%; }
    .datalist__actions__info a:hover {
      opacity: 1; }
    @media (max-width: 767px) {
      .datalist__actions__info a {
        position: relative;
        right: auto;
        top: auto; } }

.datagrid__no-records {
  padding: 30px;
  text-align: center; }

.datagrid .datagrid__center {
  text-align: center; }

.datagrid .datagrid__description {
  font-size: 1rem;
  color: #162133;
  display: block; }

.datagrid__meta {
  color: #F5F5F5;
  display: block; }

.datagrid__imported {
  display: inline-block;
  width: 45px;
  height: 45px;
  text-indent: -5000em;
  overflow: hidden;
  background-size: 18px auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../img/ico-imported.png);
  opacity: 0.3;
  transition: opacity 150ms ease-in; }
  .datagrid__imported:hover {
    opacity: 1; }

.datagrid thead th {
  font-weight: 400;
  color: #162133; }

.datagrid table {
  width: 100%; }

.datagrid tbody tr:last-child td {
  border-bottom: none; }

.datagrid tbody tr:nth-child(2n) td {
  background-color: #fbfbfb; }

.datagrid th, .datagrid td {
  padding: 10px 30px;
  text-align: left;
  vertical-align: middle;
  border-right: 1px solid #dedede;
  border-bottom: 1px solid #dedede; }
  .datagrid th:last-child, .datagrid td:last-child {
    border-right: 0; }

.datagrid__actions__edit a {
  display: inline-block;
  width: 45px;
  height: 45px;
  text-indent: -5000em;
  overflow: hidden;
  background-size: 18px auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../img/ico-edit.png);
  opacity: 0.3;
  transition: opacity 150ms ease-in; }
  .datagrid__actions__edit a:hover {
    opacity: 1; }

.datagrid__actions__view a {
  display: inline-block;
  width: 45px;
  height: 45px;
  text-indent: -5000em;
  overflow: hidden;
  background-size: 18px auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../img/ico-view.png);
  opacity: 0.3;
  transition: opacity 150ms ease-in; }
  .datagrid__actions__view a:hover {
    opacity: 1; }

/* ----------------------------------------------------------------------
 *
 * .datalist-utils
 *
 -----------------------------------------------------------------------*/
.datalist-utils {
  z-index: 99;
  margin-bottom: 30px;
  position: sticky;
  top: 85px;
  background-color: #FFFFFF;
  padding: 15px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column; }
  @media (min-width: 767px) {
    .datalist-utils {
      flex-direction: row; } }
  .datalist-utils__records {
    padding: 8px 0;
    display: block;
    border-bottom: 2px solid transparent; }
  @media (min-width: 767px) {
    .datalist-utils .pagenav {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%); } }

@media (min-width: 767px) and (min-width: 767px) {
  .datalist-utils__sort {
    width: 220px; } }
  @media (min-width: 767px) {
    .datalist-utils__records {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%); } }

/* ----------------------------------------------------------------------
 *
 * .pagenav
 *
 -----------------------------------------------------------------------*/
.pagenav {
  margin-right: 15px; }
  .pagenav, .pagenav li, .pagenav a {
    display: inline-block; }
  .pagenav a {
    padding: 8px 5px;
    margin-left: 5px;
    border-bottom: 2px solid transparent;
    transition: border-bottom 150ms ease-in; }
    .pagenav a:hover {
      cursor: hand;
      cursor: pointer;
      text-decoration: none; }
  .pagenav li.active a {
    border-bottom: 2px solid #F5F5F5; }

.button-show-filter {
  margin-bottom: 30px;
  display: block; }
  @media (min-width: 767px) {
    .button-show-filter {
      display: none; } }

.filter-wrap {
  display: none; }
  @media (min-width: 767px) {
    .filter-wrap {
      display: block; } }
  .filter-wrap--show {
    display: block !important; }

.clear-filters {
  margin-bottom: 30px; }

/* ----------------------------------------------------------------------
 *
 * .qseach
 *
 -----------------------------------------------------------------------*/
.qsearch {
  margin-bottom: 30px;
  position: relative;
  height: 40px;
  width: 100%; }
  .qsearch label {
    display: none; }
  .qsearch input[type="text"] {
    border: 1px solid #dedede;
    padding: 0 8px 0 35px;
    margin: 0 0 8px 0;
    height: 40px;
    line-height: 40px;
    width: 100%;
    font-size: 1rem;
    color: #F5F5F5;
    font-weight: 300;
    font-family: "Raleway", sans-serif;
    border-radius: 4px; }
  .qsearch:before {
    display: block;
    content: '';
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    width: 30px;
    height: 45px;
    text-indent: -5000em;
    overflow: hidden;
    background-size: 18px auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../img/ico-search.png); }
  .qsearch__clear {
    display: block;
    position: absolute;
    z-index: 2;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    width: 30px;
    height: 45px;
    text-indent: -5000em;
    overflow: hidden;
    background-size: 18px auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../img/ico-clear.png);
    opacity: 0.3;
    transition: opacity 150ms ease-in; }
    .qsearch__clear:hover {
      cursor: hand;
      cursor: pointer;
      opacity: 1; }

/* ----------------------------------------------------------------------
 *
 * .filter
 *
 -----------------------------------------------------------------------*/
.filter {
  display: block;
  margin: 0 0 15px 0;
  padding: 0 0 15px 0;
  border-bottom: 1px solid #E6E6E6;
  position: relative; }
  .filter:last-child {
    border-bottom: none;
    margin: 0 0 0 0;
    padding: 0 0 0 0; }
  .filter header {
    font-size: 1.0rem;
    font-weight: 400;
    padding: 0;
    margin: 0 0 10px 0;
    display: block;
    color: #162133; }
  .filter .remove {
    display: none;
    width: 20px;
    height: 20px;
    overflow: hidden;
    text-indent: -5000em;
    position: absolute;
    right: 0;
    top: 3px;
    background-image: url("../img/ico-remove.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 11px auto;
    opacity: 0.2;
    cursor: hand; }
    .filter .remove.show {
      display: block; }
    .filter .remove:hover {
      opacity: 0.6; }
  .filter ul li {
    margin: 0 0 10px 0;
    line-height: 24px; }
    .filter ul li label {
      display: block; }
  .filter input[type="checkbox"] {
    display: none; }
  .filter input[type="checkbox"] + label span {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin: -1px 12px 0 0;
    vertical-align: middle;
    background-color: #F7F7F7;
    border: 1px solid #ccc;
    cursor: pointer;
    position: relative; }
    .filter input[type="checkbox"] + label span em {
      display: none; }
  .filter input[type="checkbox"]:checked + label span {
    /*
	    background-image:url("../img/ico-checked.png");
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 10px auto;
	   */ }
    .filter input[type="checkbox"]:checked + label span em {
      position: absolute;
      width: 7px;
      height: 7px;
      background: #909090;
      display: block;
      left: 4px;
      top: 4px; }
  .filter input[type="radio"] {
    display: none; }
  .filter input[type="radio"] + label span {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin: -1px 8px 0 0;
    vertical-align: middle;
    background-color: #F7F7F7;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 50%;
    position: relative; }
    .filter input[type="radio"] + label span em {
      display: none; }
  .filter input[type="radio"]:checked + label span em {
    position: absolute;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background: #909090;
    display: block;
    left: 4px;
    top: 4px; }
  .filter .more {
    font-size: 0.9rem;
    margin: 0 0 10px 0;
    color: black; }
  .filter .qsearch {
    border: 1px solid #dedede;
    padding: 0 8px;
    margin: 0 0 8px 0;
    height: 33px;
    line-height: 33px;
    width: 100%;
    font-size: 1rem; }

html.svg .object-overview-filter.qsearch button {
  background-image: url("../img/ico-search-white.svg"); }

.page-header {
  margin-bottom: 60px;
  position: relative; }
  .page-header--no-padding .wrap {
    padding: 0 !important; }
  .page-header .wrap {
    padding: 0 30px;
    position: relative; }
  .page-header h1 {
    padding: 0;
    margin: 0 0 10px 0;
    line-height: 45px; }
  .page-header__back {
    padding-right: 55px;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.9rem; }
    .page-header__back:after {
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      width: 45px;
      height: 45px;
      text-indent: -5000em;
      overflow: hidden;
      margin-right: 15px;
      background-image: url(../img/ico-arrow-right-black.png);
      background-size: 8px auto;
      background-position: center center;
      background-repeat: no-repeat;
      opacity: 0.3;
      transition: opacity 150ms ease-in; }
    .page-header__back:hover {
      opacity: 1;
      text-decoration: none; }

.data-compare {
  width: 100%;
  position: relative; }
  .data-compare__row {
    padding: 20px 30px;
    text-align: left;
    vertical-align: middle;
    position: relative;
    width: 100%; }
    @media (min-width: 767px) {
      .data-compare__row {
        padding: 20px 30px; } }
    .data-compare__row:last-child {
      border-bottom: none; }
    @media (min-width: 767px) {
      .data-compare__row {
        border-bottom: 1px solid #dedede; }
        .data-compare__row:after {
          content: "";
          display: table;
          clear: both; } }
  @media (min-width: 767px) {
    .data-compare__cell1 {
      width: 33%;
      float: left; }
    .data-compare__cell2 {
      width: 33%;
      float: left; }
    .data-compare__cell3 {
      width: 33%;
      float: left; } }
  .data-compare__label {
    color: #162133;
    font-weight: 400;
    margin-bottom: 8px; }
  .data-compare__orignal span {
    display: block; }
  .data-compare__orignal__value {
    margin-bottom: 15px; }
  .data-compare__orignal__annotation {
    font-size: 0.9rem;
    color: #F5F5F5; }
  .data-compare__new__annotation {
    font-size: 0.9rem;
    color: #F5F5F5;
    display: block; }
  .data-compare .form__input {
    margin-bottom: 0; }
  .data-compare__steps {
    background-color: #ccc;
    padding: 30px 30px;
    text-align: left; }
    .data-compare__steps:after {
      content: "";
      display: table;
      clear: both; }
    .data-compare__steps .button {
      clear: both;
      margin-left: 30px;
      float: left; }
    .data-compare__steps .data-compare__row:last-child {
      border-bottom: 1px solid #dedede !important; }
  .data-compare__footer {
    padding: 30px 30px;
    text-align: right; }
    .data-compare__footer:after {
      content: "";
      display: table;
      clear: both; }
    .data-compare__footer .button {
      margin-left: 30px;
      float: right; }
    .data-compare__footer__next-record {
      float: left;
      line-height: 45px;
      height: 45px;
      position: relative;
      padding-left: 30px; }
      .data-compare__footer__next-record:after {
        content: "";
        display: table;
        clear: both; }
      .data-compare__footer__next-record label {
        float: left;
        display: block; }
      .data-compare__footer__next-record input[type='checkbox'] {
        position: absolute;
        left: 0;
        top: 15px; }
    .data-compare__footer__authorize {
      float: right;
      line-height: 45px;
      height: 45px;
      position: relative;
      padding-right: 30px; }
      .data-compare__footer__authorize:after {
        content: "";
        display: table;
        clear: both; }
      .data-compare__footer__authorize label {
        float: right;
        display: block; }
      .data-compare__footer__authorize input[type='checkbox'] {
        position: absolute;
        right: 0;
        top: 15px; }

.flex-data {
  width: 100%; }
  .flex-data form {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid #dedede;
    flex-wrap: wrap;
    padding: 20px; }
    @media (min-width: 767px) {
      .flex-data form {
        flex-direction: row; } }
  .flex-data__row {
    padding: 10px;
    text-align: left;
    vertical-align: middle;
    position: relative;
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: start; }
  .flex-data:last-child {
    border-bottom: none; }
  .flex-data__cell2 {
    width: 60%;
    display: flex;
    justify-content: flex-end; }
  .flex-data .form__input {
    width: 100%;
    text-align: right; }
    .flex-data .form__input textarea {
      height: auto;
      min-height: 100px;
      resize: vertical; }
  .flex-data__label {
    color: #162133;
    font-weight: 400;
    margin-bottom: 8px; }
  .flex-data__orignal span {
    display: block; }
  .flex-data__orignal__value {
    margin-bottom: 15px; }
  .flex-data__orignal__annotation {
    font-size: 0.9rem;
    color: #F5F5F5; }
  .flex-data__new__annotation {
    font-size: 0.9rem;
    color: #F5F5F5;
    display: block; }
  .flex-data .form__input {
    margin-bottom: 0; }
  .flex-data__steps {
    background-color: #ccc;
    padding: 30px 30px;
    text-align: left; }
    .flex-data__steps .button {
      clear: both;
      margin-left: 30px;
      float: left; }

.tabbed-navigation {
  display: inline-block;
  margin-bottom: 45px;
  border-bottom: 1px solid #dedede;
  width: 100%; }
  .tabbed-navigation:after {
    content: "";
    display: table;
    clear: both; }
  .tabbed-navigation li {
    display: block;
    float: left;
    padding: 15px 0px;
    text-align: center;
    border-radius: 5px 5px 0 0;
    margin-left: -1px;
    margin-bottom: -1px;
    margin-right: 45px;
    color: #162133; }
    .tabbed-navigation li.active {
      border-bottom: 4px solid #409EE4; }
    .tabbed-navigation li:hover {
      cursor: hand;
      cursor: pointer; }

.dashboard_chart {
  height: 400px; }
  @media (max-width: 767px) {
    .dashboard_chart {
      height: 600px; } }

.toplist__item {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #dedede;
  display: table;
  table-layout: fixed;
  width: 100%; }
  .toplist__item__col {
    display: table-cell; }
    .toplist__item__col:nth-child(1) {
      width: 50px;
      vertical-align: middle;
      text-align: left; }
  .toplist__item:last-child {
    border-bottom: none; }
  .toplist__item span {
    display: block; }
  .toplist__item__title {
    color: #162133;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0; }
  .toplist__item__meta {
    font-size: 0.9rem; }
  .toplist__item__position {
    font-size: 1rem;
    border: 2px solid #dedede;
    border-radius: 100%;
    display: inline-block;
    width: 45px;
    height: 45px;
    line-height: 42px;
    text-align: center;
    margin-left: -22px; }

.weekcal__item {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #dedede; }
  .weekcal__item:last-child {
    border-bottom: none; }
  .weekcal__item span {
    display: block; }
  .weekcal__item__title {
    color: #162133;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0; }
  .weekcal__item__date {
    font-size: 0.9rem; }

/* ----------------------------------------------------------------------
 *
 * FAQ
 *
 -----------------------------------------------------------------------*/
.faq {
  margin-bottom: 30px; }
  .faq__item {
    border-bottom: 1px solid #dedede; }
    .faq__item__question {
      font-weight: 300;
      color: #162133;
      padding: 15px 0; }
    .faq__item__answer {
      display: none; }
  .faq__item--active .faq__item__answer {
    display: block;
    padding-bottom: 15px; }

.notification-bar {
  position: fixed;
  z-index: 888;
  bottom: 30px;
  left: 50%;
  width: 300px;
  padding: 20px 60px 20px 20px;
  text-align: center;
  transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  border-radius: 3px; }
  .notification-bar:hover {
    cursor: hand;
    cursor: pointer; }
  .notification-bar--warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc; }
  .notification-bar--succes {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6; }
  .notification-bar--info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1; }
  .notification-bar--danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1; }
  .notification-bar:hover .notification-bar__close {
    opacity: 1;
    cursor: pointer;
    cursor: hand; }
  .notification-bar__close {
    display: block;
    overflow: hidden;
    text-indent: -5000em;
    width: 45px;
    height: 45px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(../img/ico-close-black.png);
    background-size: 14px auto;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.2;
    transition: opacity 150ms ease-in; }
  .notification-bar--hide {
    transform: translate(-50%, 200%); }
  .notification-bar--show {
    transform: translate(-50%, 0%); }

.thumbnail {
  width: 100%;
  padding-bottom: 100%;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
  border: 1px solid #dedede;
  display: inline-block; }
  .thumbnail--small {
    width: 75px;
    height: 75px;
    padding-bottom: 0;
    margin-bottom: 0; }
  .thumbnail--noborder {
    border: none; }
  .thumbnail img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto; }

.actionbar {
  position: relative;
  margin-bottom: 30px; }
  .actionbar .form__select, .actionbar .button {
    margin-bottom: 15px; }
  @media (min-width: 767px) {
    .actionbar:after {
      content: "";
      display: table;
      clear: both; }
    .actionbar .form__select {
      width: 220px;
      float: left; }
    .actionbar .button {
      float: right;
      margin-left: 15px; } }

.filepreview {
  margin-bottom: 30px; }
  .filepreview__title {
    display: block;
    margin-bottom: 10px;
    font-size: 1.1rem;
    color: #162133; }
  .filepreview__meta {
    display: block;
    margin-bottom: 20px; }
  .filepreview__specs:after {
    content: "";
    display: table;
    clear: both; }
  .filepreview__specs dt, .filepreview__specs dd {
    margin-bottom: 5px;
    font-size: 1em; }
  .filepreview__specs dt {
    float: left;
    clear: left;
    width: 100px; }
  .filepreview__specs dd {
    margin: 0 0 0 110px; }

.filedropzone {
  padding: 30px;
  border: 1px dotted #ccc;
  text-align: center; }

.file-browser__content {
  float: left;
  width: calc(100% - 400px);
  padding: 30px;
  min-height: 600px; }

.file-browser__aside {
  float: left;
  width: 400px;
  padding: 30px;
  background-color: #F3F0EB;
  background-color: #fff;
  min-height: 600px; }

.file-browser__footer {
  width: 100%;
  clear: both;
  text-align: right;
  padding: 30px;
  border-top: 1px solid #dedede;
  background-color: #fff; }
  .file-browser__footer .button {
    margin-left: 15px; }

.messages-bar {
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  visibility: hidden;
  transition: visibility 150ms ease-in; }
  .messages-bar--active {
    visibility: visible;
    opacity: 1; }
    .messages-bar--active .messages-bar__transparant {
      opacity: 1; }
    .messages-bar--active .messages-bar__inner {
      opacity: 1;
      transform: translateX(0%); }
    .messages-bar--active .messages-bar__close {
      opacity: 0.5;
      transform: translateY(0); }
  .messages-bar__close {
    position: fixed;
    right: 15px;
    top: 15px;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    display: block;
    z-index: 3;
    overflow: hidden;
    text-indent: -5000em;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../img/ico-close-black.png);
    background-size: 14px auto;
    opacity: 0;
    transition: opacity 100ms ease-in 500ms; }
    .messages-bar__close:hover {
      transition: opacity 150ms ease-in;
      opacity: 1;
      cursor: hand;
      cursor: pointer; }
  .messages-bar__inner {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    background-color: #fff;
    height: 100%;
    overflow: hidden;
    overflow-y: visible;
    z-index: 2;
    padding: 30px;
    max-width: 420px;
    width: 90%;
    transform: translateX(100%);
    transition: transform 150ms ease-in 250ms; }
  .messages-bar__transparant {
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 1;
    opacity: 0;
    transition: opacity 150ms ease-in; }
  .messages-bar__title {
    display: block;
    margin-bottom: 45px;
    font-weight: 400;
    color: #000;
    font-size: 1.2rem; }
  .messages-bar__item {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #dedede;
    position: relative;
    width: calc(100% - 65px);
    margin-left: 65px; }
    .messages-bar__item:last-child {
      border-bottom: none; }
    .messages-bar__item span {
      display: block; }
    .messages-bar__item__icon {
      display: block;
      position: absolute;
      left: -65px;
      top: 0;
      width: 45px;
      height: 26px;
      border-radius: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url(../img/ico-notification-black.png);
      background-size: 22px auto;
      opacity: 0.5; }
    .messages-bar__item__title {
      font-weight: 400;
      color: #162133;
      margin-bottom: 15px;
      font-size: 0.9rem; }
    .messages-bar__item__message {
      font-size: 0.9rem;
      line-height: 1.4rem;
      margin-bottom: 15px;
      color: #F5F5F5; }
    .messages-bar__item__time {
      font-size: 0.9rem;
      margin-bottom: 0px;
      color: #162133;
      font-weight: 400; }

.datainput {
  position: relative; }
  .datainput__results {
    display: none;
    border: 1px solid #dedede;
    height: 320px;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    bottom: 0;
    top: 100%;
    z-index: 2;
    background: #fff; }
    .datainput__results--active {
      display: block; }
    .datainput__results li {
      padding: 15px;
      transition: background-color 150ms ease-in; }
      .datainput__results li:hover {
        color: #fff;
        background-color: #409EE4;
        cursor: pointer;
        cursor: hand; }
  .datainput__actions {
    width: 92px;
    height: 43px;
    background-color: #FBFBFB;
    border-radius: 0/3px/3px/0;
    border-left: 1px solid #dedede;
    position: absolute;
    right: 1px;
    bottom: 1px; }
    .datainput__actions:after {
      content: "";
      display: table;
      clear: both; }
    .datainput__actions span {
      display: block;
      float: left;
      width: 45px;
      height: 43px;
      background-position: center center;
      background-repeat: no-repeat;
      overflow: hidden;
      text-indent: -50000em;
      opacity: 0.4;
      transition: opacity 150ms ease-in; }
      .datainput__actions span:first-child {
        border-right: 1px solid #dedede; }
      .datainput__actions span:hover {
        cursor: hand;
        cursor: pointer;
        opacity: 1; }
    .datainput__actions__add {
      background-image: url("../img/ico-check-black.png");
      background-size: 11px auto; }
    .datainput__actions__remove {
      background-image: url("../img/ico-close-black.png");
      background-size: 11px auto; }

.progress-table {
  overflow: hidden;
  width: 100%; }
  .progress-table__subheading {
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 15px;
    background-color: #F5F5F5;
    display: block; }
    .progress-table__subheading span {
      display: inline-block;
      text-transform: none;
      float: right;
      font-size: 0.9rem;
      font-weight: 300; }
  .progress-table__table-wrap {
    overflow: hidden;
    width: 100%;
    overflow-x: auto;
    margin-bottom: 30px; }
  .progress-table table {
    border-collapse: collapse;
    width: 100%; }
  .progress-table th, .progress-table td {
    padding: 20px 10px;
    border-bottom: 1px solid #dedede; }
  .progress-table tbody tr:last-child th, .progress-table tbody tr:last-child td {
    border-bottom: none; }
  .progress-table th {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 0.9rem; }
  .progress-table__bar {
    background-color: #F5F5F5;
    height: 8px;
    border-radius: 8px;
    position: relative; }
    .progress-table__bar--behind span {
      background-color: red;
      width: 33.333%; }
    .progress-table__bar--ontrack span {
      background-color: orange;
      width: 66.66%; }
    .progress-table__bar--done span {
      background-color: green;
      width: 100%; }
    .progress-table__bar span {
      position: absolute;
      height: 8px;
      width: 22%;
      border-radius: 8px;
      display: inline-block; }

.timer {
  background-color: #fff;
  position: fixed;
  z-index: 888;
  bottom: 40px;
  right: 40px;
  display: block;
  box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.2); }
  .timer:after {
    content: "";
    display: table;
    clear: both; }
  .timer__cell {
    display: block;
    float: left;
    height: 80px;
    position: relative; }
    .timer__cell:nth-child(1) {
      width: 80%;
      width: 200px; }
    .timer__cell:nth-child(2) {
      width: 80px;
      border-left: 1px solid rgba(255, 255, 255, 0.2); }
    .timer__cell:nth-child(3) {
      width: 80px;
      border-left: 1px solid rgba(255, 255, 255, 0.2); }
      .timer__cell:nth-child(3) div {
        background-color: green; }
  .timer__time {
    font-size: 2.0rem;
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%); }
  .timer__button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    background-color: #409EE4;
    color: #fff;
    transition: background-color 150ms ease-in; }
    .timer__button--stop {
      background-color: red; }
    .timer__button:hover {
      background-color: #1869a6;
      cursor: pointer;
      cursor: hand; }

.logwork {
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  visibility: hidden;
  transition: visibility 150ms ease-in; }
  .logwork--active {
    visibility: visible;
    opacity: 1; }
    .logwork--active .logwork__transparant {
      opacity: 1; }
    .logwork--active .logwork__inner {
      opacity: 1;
      transform: translateX(0%); }
    .logwork--active .logwork__close {
      opacity: 0.5;
      transform: translateY(0); }
  .logwork__close {
    position: fixed;
    right: 15px;
    top: 15px;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    display: block;
    z-index: 3;
    overflow: hidden;
    text-indent: -5000em;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../img/ico-close-black.png);
    background-size: 14px auto;
    opacity: 0;
    transition: opacity 100ms ease-in 500ms; }
    .logwork__close:hover {
      transition: opacity 150ms ease-in;
      opacity: 1;
      cursor: hand;
      cursor: pointer; }
  .logwork__inner {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    background-color: #fff;
    height: 100%;
    overflow: hidden;
    overflow-y: visible;
    z-index: 2;
    padding: 40px;
    max-width: 420px;
    width: 90%;
    transform: translateX(100%);
    transition: transform 150ms ease-in 250ms;
    text-align: center; }
  .logwork__transparant {
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 1;
    opacity: 0;
    transition: opacity 150ms ease-in; }
  .logwork__table {
    display: table;
    table-layout: inherit;
    height: 100%;
    width: 100%; }
  .logwork__content {
    height: 100%;
    display: table-cell;
    vertical-align: middle; }
  .logwork__title {
    display: block;
    margin-bottom: 15px;
    font-size: 2.0rem; }
  .logwork__subtitle {
    display: block;
    margin-bottom: 15px; }
  .logwork__timer {
    display: block;
    margin-bottom: 60px;
    font-size: 1.6rem;
    font-weight: 500; }
  .logwork__input {
    margin: 0 auto 30px auto; }
    .logwork__input label {
      display: block;
      margin-bottom: 8px; }
  .logwork .button {
    width: 100%; }

/* Custom dialog/modal headers */
.dialog-header-error {
  background-color: #d2322d; }

.dialog-header-wait {
  background-color: #428bca; }

.dialog-header-notify {
  background-color: #eeeeee; }

.dialog-header-confirm {
  background-color: #333333; }

.dialog-header-error span, .dialog-header-error h4,
.dialog-header-wait span, .dialog-header-wait h4,
.dialog-header-confirm span, .dialog-header-confirm h4 {
  color: #ffffff; }

.fade.in {
  display: block !important; }

.ta-bind p i {
  font-style: italic !important; }

.ta-bind div i {
  font-style: italic !important; }

.ta-bind ul {
  list-style-type: disc !important;
  margin-left: 15px !important; }
  .ta-bind ul li {
    margin-left: 15px !important; }

.ta-bind ol {
  list-style-type: decimal !important;
  margin-left: 15px !important; }
  .ta-bind ol li {
    margin-left: 15px !important; }

/* ----------------------------------------------------------------------
 *
 * LOGIN
 *
 -----------------------------------------------------------------------*/
body.page-login {
  padding-top: 30px; }
  @media (min-width: 992px) {
    body.page-login {
      padding-top: 0; } }

html, body.page-login {
  background-color: #fff; }
  @media (min-width: 992px) {
    html, body.page-login {
      height: 100%; } }

.login .logo-peugeot {
  margin: 0 auto;
  margin-bottom: 45px; }

@media (min-width: 992px) {
  .login {
    height: 100%; } }

.login__visual {
  display: none;
  float: left;
  width: 50%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-image: url("../img/login-visual.jpg"); }
  @media (min-width: 992px) {
    .login__visual {
      display: block; } }

@media (min-width: 992px) {
  .login__form {
    margin: 0;
    width: 100%;
    background-color: #fff;
    position: relative;
    float: left;
    width: 50%;
    height: 100%; } }

.login__form form {
  width: calc(100% - 60px);
  max-width: 420px;
  margin: 0 auto; }
  @media (min-width: 767px) {
    .login__form form {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 500px;
      margin: 0; } }
  .login__form form h3 {
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 45px;
    text-transform: uppercase; }
  .login__form form .button {
    margin: 0 0 30px 0; }

.login__form__password-request {
  text-align: center;
  margin-bottom: 30px;
  font-size: 0.9rem;
  display: block; }

.login .social-login p {
  text-align: center;
  margin-bottom: 30px;
  font-size: 0.9rem; }

.login .social-login .button {
  width: 48%;
  margin-right: 4%;
  float: left; }
  .login .social-login .button:last-child {
    margin-right: 0; }
