
/* ----------------------------------------------------------------------
 *
 * HELPERS
 *
 -----------------------------------------------------------------------*/



@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.clear{
  clear: both;

}



.pull-to-center{
	float:none !important;
	margin:0 auto;
}


.pull-to-left{
	float:right !important;
}


  .pull-to-right{
    @include respond-to(small){
  	float:right !important;
    }

  }


  .kleur-red {
    color: red !important;
  }

  .kleur-green {
    color:green;
  }



  .formvalidationerror {
    padding: 10px;
    background-color: #f44336; /* Red */
    color: white;
    //margin: 15px;
    width:100%;
   // height:50px;
    font-weight:500;
  }




  .tooltip {
    //width: 100%;
    font-size: 14px;
  }


  .disabled {
    opacity: 0.6;
    color: red !important;
  }
