/* ----------------------------------------------------------------------
 *
 * POPUP
 *
 -----------------------------------------------------------------------*/



.popup{

  position: fixed;
  z-index: 999;
  left:0;
  top:0;
  bottom:0;
  right:0;
  overflow:hidden;




  &--no-padding{

    .popup__inner{

      padding:0;
    }

  }


  &--small{

  	.popup__window{

	  	 @include respond-to(small){
         width:800px;
         height:300px;
	    }

  	}
  }


  &__window{

    background: #fff;
    position: absolute;
    left:50%;
    margin-bottom: 30px;
    //top:50%;
    width:90%;
    //height:auto;
    @include respond-to(small){
      //width:800px;
      //height:600px;
    }

    border-radius: 5px;

  }

  &--open{

    background: rgba(0,0,0,0.5);
    transition: background 200ms ease-in;
    visibility: visible;
      overflow-y: visible;

    .popup__window{
      transform: translate(-50%,30px);
      transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1) 200ms;
    }
  }

  &--close{


    background: rgba(0,0,0,0.0);
    transition: background 200ms ease-in 200ms;
    visibility: hidden;

    .popup__window{
        transform: translate(-50%,400%);
        transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    }

  }


  &__header{

    border-bottom:1px solid #dedede;
    padding:20px 30px;
    position: relative;
    text-transform: uppercase;
    color:$color-gray-01;
    font-size: 0.9rem;
    font-weight: 400;
    background-color: $color-gray-02;
     border-radius: 5px 5px 0 0;

  }

  &__inner{

    padding:30px;

  }

  &__close{

    background: none;
    border:none;
    display: block;
    width:32px;
    height:32px;
    //background: #ccc;
    position: absolute;
    right:15px;
    top:50%;
    transform: translateY(-50%);
    overflow:hidden;
    text-indent: -5000em;
    opacity:0.4;
    transition: opacity 150ms ease-in;

    &:focus{
      outline: none;
    }

    &:hover{
     opacity:1;
    }

	background-position:center center;
	background-repeat:no-repeat;
	background-image:url(../img/ico-close-black.png);
	background-size:14px auto;
  }

}
