.thumbnail{

  width: 100%;
  padding-bottom:100%;
  margin-bottom: $base-unit;
  overflow:hidden;
  position: relative;
  border: 1px solid #dedede;
  display: inline-block;
  //background-color: #fff;
  //float: right;


  &--small{
    width: 75px;
    height: 75px;
    padding-bottom:0;
    margin-bottom: 0;

    img{
      //width: 60% !important;
    }
  }

  &--noborder{
    border: none;
  }


  img{
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: auto;
  }


}
