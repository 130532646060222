/* ----------------------------------------------------------------------
*
* FORM
*
-----------------------------------------------------------------------*/
.validation {
    font-size: 0.8rem;
    color: red; //$color-primary-01;
    margin-top: 8px;
}

.ng-submitted .form__input {
    .ng-invalid {
        border: 1px solid red !important;
    }
}

.form__group {
    margin-bottom: $base-unit;
}

.form__file {
    margin-bottom: $base-unit;

    .button {
        margin-bottom: 15px;
    }

    &__progress {
        margin-bottom: 15px;
        @include clearfix();

        &__bar {
            float: left;
            width: 300px;
            height: 15px;
            border: 1px solid #dedede;
            margin-right: 15px;

            span {
                display: block;
                background-color: $color-primary-02;
                height: 100%;
            }
        }

        &__percentage {
            float: left;
        }

        &__annotation {
            font-size: 0.9rem;
        }
    }
}

.form__multiselect {
    input[type="checkbox"] {
        display: none;
    }

    label:hover {
        cursor: pointer;
        cursor: hand;
    }

    &__checkbox {
        border: 1px solid #ccc;
        background-color: #fff;
        width: 16px;
        height: 16px;
        display: inline-block;
        border-radius: 2px;
        margin-right: 8px;
        position: relative;

        &:after {
            width: 9px;
            height: 9px;
            content: '';
            display: none;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background-color: $color-primary-02;
        }

        &--active {
            &:after {
                display: block;
            }
        }
    }

    &__label {
        height: 18px;
        display: inline-block;
        line-height: 16px;
    }
}

.form__select {
    overflow: hidden;
    position: relative;
    border: 1px solid #dedede;
    width: 100%;
    border-radius: 3px;
    background-color: #fff;
    margin-bottom: 10px;

    &:hover {
        cursor: hand;
        cursor: pointer;

        &:after {
            opacity: 1;
        }
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 32px;
        height: 32px;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("../img/ico-arrow-down-black.png");
        background-position: 96% 49%;
        background-repeat: no-repeat;
        background-size: 11px auto;
        opacity: 0.6;
        transition: opacity 150ms ease-in;
    }
    @include respond-to(small) {
        margin-bottom: 0;
        //max-width: 320px;
    }

    select {
        box-sizing: border-box;
        padding: 12px 15px;
        width: 100%;
        font-size: 1.3rem;
        font-weight: 300;
        font-family: $font-stack;
        border: none;
        box-shadow: none;
        background: transparent;
        background-image: none;
        -webkit-appearance: none;

        &:hover {
            cursor: hand;
            cursor: pointer;
        }

        &:focus {
            outline: none;
        }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form__select:after {
    content: none;
    display: none;
    width: 0px;
  }
}

.form__select.datalist-utils__sort {
    @include respond-to(small) {
        margin-right: 10px;
    }
}

.form__input {
    margin: 0 0 30px;
    position: relative;

    &__add {
        @include clearfix();

        .form__select {
            width: calc(100% - 60px);
            float: left;
        }

        &__button {
            float: right;
            display: block;
            width: 43px;
            height: 43px;
            background-color: #f5f5f5;
            line-height: 43px;
            text-align: center;
            border-radius: 100%;
            transition: all 150ms ease-in;
            font-size: 1.1rem;
            text-indent: -5000em;
            overflow: hidden;
            background-image: url("../img/ico-plus-black.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 11px auto;

            &:hover {
                cursor: pointer;
                cursor: hand;
                background-color: $color-primary-02;
                color: #fff;
                background-image: url("../img/ico-plus-white.png");
            }
        }
    }

    label {
        display: block;
        color: $color-primary-01;
        margin: 0 0 15px;
        text-transform: uppercase;
    }

    &__disabled {
        display: block;
    }

    input[type='password'],
    input[type='text'],
    input[type='time'],
    input[type='number'],
    textarea {
        border: 1px solid darken($color-gray-01,10%);
        padding: 12px 15px;
        width: 100%;
        font-size: 1.5rem;
        font-weight: 300;
        font-family: $font-stack;
        //border-radius: 3px;
        background-color: $color-gray-01;
        transition: border 200ms ease-in;

        &.datepicker {
            background-image: url("../img/ico-date-black.png");
            background-position: 97% 49%;
            background-repeat: no-repeat;
            background-size: 18px auto;
        }

        &:focus {
            border: 1px solid $color-primary-01;
            outline: none;
        }
    }

    textarea {
        height: 360px;
    }
}
.date_group {
    .entypo-calendar {
        display: inline;
    }
}

.form__footer {
    padding: 30px 0;
    text-align: right;

    .button {
        margin-bottom: 15px;
        @include respond-to(small) {
            margin-left: 15px;
        }
    }
}


.after_form {
    margin-top:15px;
    float:left;
}