/* ----------------------------------------------------------------------
 *
 * global-nav
 *
 -----------------------------------------------------------------------*/
.toggle-nav {
    display: block;
    width: 40px;
    height: 45px;
    //transition: .5s ease-in-out;
    cursor: pointer;
    cursor: hand;
    position: absolute;
    left: 18px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    background-image: url("../img/ico-hamburger.png");
    background-size: 24px auto;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0.6;
    transition: opacity 200ms ease-in;
    &--open {}
}

.page-wrap {
    //transition: width 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
$global-nav-width: 340px;

.global-nav {

    // nieuwe aanpassingen

    .form__select {
        margin-left:30px;
        margin-bottom:30px;
        width: 80%;
    }


    // einde nieuwe aanpassingen

    &__transparent {
        display: block;
        content: '';
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        z-index: 990;
        display: none;

        &:hover {
            cursor: pointer;
            cursor: hand;
        }
    }

    //transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    &__inner {
        background: $color-primary-01;
        height: 100%;
        position: fixed;
        transform: translate(-100%,0);
        bottom: 0;
        top: 0;
        z-index: 999;
        overflow: hidden;
        overflow-y: auto;
        width: 90%;
        @include respond-to(small) {
            width: $global-nav-width;
        }
    }

    .button {
        width: 90%;
        display: block;
        margin: 30px auto;
        @include respond-to(small) {
            display: none;
        }
    }

    &__logo {
        margin: 0 auto;
        text-align: center;
        width: 100%;
        margin: 30px 0;
        /*
     @include respond-to(small){
       position: absolute;
       bottom:$base-unit;
       left:50%;
      transform: translateX(-50%);
    }*/
        .logo-peugeot {
            display: inline-block;
            margin-bottom: 20px;
            background-size: 120px auto;
            width: 120px;
            height: 86px;
        }

        span {
            display: block;
            color: #fff;
            font-size: 0.9rem;
            opacity: 1;
        }
    }

    &__menu {
        margin-bottom: 30px;

        &__more {
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            width: 60px;
            height: 60px;
            //background-color: blue;
            background-image: url("../img/ico-plus-white.png");
            background-size: 14px auto;
            background-repeat: no-repeat;
            background-position: center center;
            opacity: 0.6;
            transition: opacity 150ms ease-in;

            &:hover {
                opacity: 1;
                cursor: pointer;
                cursor: hand;
            }
        }

        ul {
            li.active {
                .global-nav__menu__more {
                    background-image: url("../img/ico-min-white.png");
                }

                & > a {
                    //border-left:5px solid $color-primary-02;
                }

                ul {
                    display: block;
                }
            }

            li {
                position: relative;
                border-bottom: 1px solid rgba(255,255,255,0.09);
                padding-right: 60px;

                a {
                    display: block;
                    text-decoration: none;
                    font-size: 1rem;
                    color: $color-primary-03;
                    text-transform: uppercase;
                    font-weight: 400;
                    padding: 22px $base-unit;
                    transition: padding 150ms ease-in;
                    position: relative;
                    border-left: 5px solid $color-primary-01;

                    &:hover {
                        text-decoration: none;
                        cursor: hand;
                        cursor: pointer;
                    }

                    .counter {
                        background-color: #0393CF;
                        width: 28px;
                        height: 28px;
                        border-radius: 100%;
                        font-size: 0.8rem;
                        display: inline-block;
                        text-align: center;
                        line-height: 28px;
                        position: absolute;
                        right: 30px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
                // level 2
                ul {
                    padding-bottom: 20px;

                    li {
                        border-bottom: none; //1px solid rgba(255,255,255,0.09);

                        &.active a {
                            border-left: 5px solid $color-primary-02;
                        }

                        &.active > a > span {
                            opacity: 1;
                        }

                        a {
                            //  padding-left: 46px;
                            font-weight: 300;
                            padding-top: 10px;
                            padding-bottom: 10px;

                            span {
                                font-size: 1.2rem;
                                opacity: 0.6;
                                font-weight:400;
                            }
                        }
                    }
                }
            }
        }
    }
}

html.html--show-nav {
    &,
    body {}

    body {}

    .header {
        width: inherit;
    }

    .page-wrap {
        width: 100%;
        //transform: translateX(260px);
        @include respond-to(small) {
            transform: none;
            //transform: translateX(340px);
            width: calc(100% - 340px);
            float: right;
        }
        height: 100%;
    }

    .global-nav__transparent {
        display: block;
        @include respond-to(small) {
            display: none !important;
        }
    }

    .global-nav__inner {
        transform: translate(0,0);
    }
}
