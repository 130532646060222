.ta-bind {

  p  {
    i {
      font-style:italic !important;
    }
  }

  div  {
    i {
      font-style:italic !important;
    }
  }


  ul {
    list-style-type: disc !important;
    margin-left:15px !important;
    & li {
      margin-left:15px !important;
    }
  }

  ol {
    list-style-type: decimal !important;
    margin-left:15px !important;
    & li {
      margin-left:15px !important;
    }
  }

}

