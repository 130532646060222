.datalist {
    .bluetooth {
        cursor:pointer;
    }
    &__group-header {
        background-color: #fff;
        text-align: left;
        padding: 20px;
        border-bottom: 1px solid #dedede;
        font-size: 1.2rem;
        color: $color-primary-01;
        background-color: #fff;
    }
    border: 1px solid #dedede;
    //border-bottom: none;
    margin-bottom: ($base-unit*1.5);
    background: #fff;
    @include respond-to(small) {
        &--toggle-active {
            .datalist__item {
                padding-left: 90px;
            }
        }
    }

    &__container {
        overflow: hidden;
        overflow-y: scroll;
    }

    &__toggle {
        height: 45px;
        width: 45px;
        line-height: 45px;
        position: absolute;
        left: 15px;
        top: 15px;
        overflow: hidden;
        text-indent: -5000em;
        display: none;
        @include respond-to(small) {
            display: block;
        }
        background-size: 14px auto;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url("../img/ico-plus.png");
        opacity: 0.3;
        transition: opacity 150ms ease-in;

        &:hover {
            opacity: 1;
            cursor: hand;
            cursor: pointer;
        }
    }

    &__cells {
        width: 100%;
        display: block;
        margin-bottom: 0;
        @include respond-to(small) {
            display: table;
            margin-bottom: 0;
        }

        .datalist__cell {
            display: block;
            line-height: 1.4;

            &--strong {
                font-weight: 400;
                color: $color-primary-01;
            }
            @include respond-to(small) {
                &--strong {
                    font-weight: 600;
                    //color:$color-gray-01;
                }
                display: table-cell;
                vertical-align: middle;
                width: 20%;
                height: 75px;

                &--thumb {
                    width: 8%;
                }
            }
        }
    }

    &__item {
        padding: 15px 10px;
        width: 100%;
        background: #fff;
        border-bottom: 1px solid #dedede;
        position: relative;
        @include clearfix();
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @include respond-to(small) {
            padding: 15px 20px;
        }

        &__left {
            width: calc(100% - 70px);
            margin-right: 20px;
            @include respond-to(small) {
                width: 70%;
                float: left;
            }
        }

        &__right {
            width: 50px;
            @include respond-to(small) {
                width: 20%;
                float: left;
                text-align: right;
            }
        }

        &--headings {
            display: none;
            padding-top: 10px;
            padding-bottom: 10px;
            @include respond-to(small) {
                display: block;
            }

            .datalist__cell {
                color: $color-primary-01;
                font-weight: 400;
                text-transform: uppercase;
                font-size: 1.2rem;
            }
        }

        &:last-child {
            border-bottom: none;
        }

        &--odd {
            background-color: $color-gray-03;
        }

        &--selected {
            //background-color: $color-gray-01 !important;
            border: 1px solid $color-gray-01;
        }

        &--active {
            .datalist__item__quickview {
                display: block;
            }
        }

        &__quickview {
            display: none;
            padding: 15px 0 30px;
            @include clearfix();

            &__item {
                @include clearfix();
                float: left;
                width: 50%;
                margin-bottom: 10px;

                span {
                    display: block;
                    width: 50%;
                    float: left;
                    padding: 0;
                }
            }
        }
    }
    // basics
    &__no-records {
        padding: $base-unit;
        text-align: center;
        border-bottom: 1px solid #dedede;
    }

    .datalist__center {
        text-align: center;
    }

    .datalist__description {
        font-size: 1rem;
        color: $color-primary-01;
        display: block;
        margin-bottom: 10px;
        font-weight: 400;
    }

    &__meta {
        //font-size: 0.9rem;
        color: $color-gray-01;
        display: block;
    }

    &__imported {
        display: inline-block;
        width: 45px;
        height: 45px;
        text-indent: -5000em;
        overflow: hidden;
        background-size: 18px auto;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url("../img/ico-imported.png");
        opacity: 0.3;
        transition: opacity 150ms ease-in;

        &:hover {
            opacity: 1;
        }
    }
    // actions
    &__actions {
        &,
        a,
        li {
            display: inline-block;
            margin: 0;
        }

        span {
            display: none;
        }

        li {
            border: 1px solid #dedede;
            border-radius: 3px;
            @include respond-to(small) {
                border: none;
                border-radius: 0;
            }
        }

        &__move span {
            display: inline-block;
            width: 45px;
            height: 45px;
            overflow: hidden;
            background-size: 19px auto;
            background-position: center center;
            background-repeat: no-repeat;
            background-image: url("../img/ico-list-order.png");
            opacity: 0.3;
            transition: opacity 150ms ease-in;

            &:hover {
                opacity: 1;
                cursor:move;
            }
            cursor:move;
        }

        &__duplicate a {
            display: inline-block;
            width: 45px;
            height: 45px;
            overflow: hidden;
            background-size: 19px auto;
            background-position: center center;
            background-repeat: no-repeat;
            background-image: url("../img/ico-duplicate-black.png");
            opacity: 0.3;
            transition: opacity 150ms ease-in;

            &:hover {
                opacity: 1;
            }
        }

        &__delete a {
            display: inline-block;
            width: 45px;
            height: 45px;
            overflow: hidden;
            background-size: 22px auto;
            background-position: center center;
            background-repeat: no-repeat;
            background-image: url("../img/ico-delete-red.png");
            opacity: 0.3;
            transition: opacity 150ms ease-in;

            &:hover {
                opacity: 1;
            }
        }

        &__edit a {
            display: inline-block;
            width: 45px;
            height: 45px;
            overflow: hidden;
            background-size: 18px auto;
            background-position: center center;
            background-repeat: no-repeat;
            background-image: url("../img/ico-edit.png");
            opacity: 0.3;
            transition: opacity 150ms ease-in;

            &:hover {
                opacity: 1;
            }
        }

        &__view a {
            display: inline-block;
            width: 45px;
            height: 45px;
            overflow: hidden;
            background-size: 18px auto;
            background-position: center center;
            background-repeat: no-repeat;
            background-image: url("../img/ico-view.png");
            opacity: 0.3;
            transition: opacity 150ms ease-in;

            &:hover {
                opacity: 1;
            }
        }

        &__user a {
            display: inline-block;
            width: 45px;
            height: 45px;
            overflow: hidden;
            background-size: 18px auto;
            background-position: center center;
            background-repeat: no-repeat;
            background-image: url("../img/ico-user-black.png");
            opacity: 0.3;
            transition: opacity 150ms ease-in;

            &:hover {
                opacity: 1;
            }
        }

        &__info a {
            display: inline-block;
            width: 45px;
            height: 45px;
            overflow: hidden;
            background-size: 18px auto;
            background-position: center center;
            background-repeat: no-repeat;
            background-image: url("../img/ico-info.png");
            position:absolute;
            right:30px;
            top:25%;
            &:hover {
                opacity: 1;
            }

            @include respond-to(mobile) {
                position: relative;
                right: auto;
                top: auto;
            }

        }
    }
}
