


/* ----------------------------------------------------------------------
 *
 * GRID SYSTEM
 *
 -----------------------------------------------------------------------*/


$grid-columns: 12;
$grid-max-width: 1940px;
$grid-gutter: 30px;
$base-unit:30px;

.wrap{


	max-width:$grid-max-width;
	margin:0 auto;
	position:relative;

}

.row{

	@include respond-to(small){
			//padding:30px 0;
	}

}

[class*='col'] {

    padding: 0 15px;

    @include respond-to(small){
      padding: 0 $grid-gutter;
    }

    width: 100%;
    min-height: 1px;
    margin-bottom:$base-unit;
    position:relative;
}

.col-hide{

	display:none;

}

@include respond-to(small){

	.wrap,.row{

		@include clearfix;

	}

	[class*='col-s'] {
	 	float: left;
	  	margin-bottom:0;
	  	display:block;
	}


	@for $i from 1 through $grid-columns {

	    .col-s-#{$i} {
	        width: 100% / $grid-columns * $i;
	    }

	}
}


@include respond-to(medium){

	.wrap,.row{

		@include clearfix;

	}

	[class*='col-m'] {
	 	float: left;
	  	margin-bottom:0;
	  	display:block;
	}


	@for $i from 1 through $grid-columns {

	    .col-m-#{$i} {
	        width: 100% / $grid-columns * $i;
	    }

	}
}



@include respond-to(large){

	.wrap,.row{

		@include clearfix;

	}

	[class*='col-l'] {
	 	float: left;
	  	margin-bottom:0;
	  	display:block;
	}


	@for $i from 1 through $grid-columns {

	    .col-l-#{$i} {
	        width: 100% / $grid-columns * $i;
	    }

	}
}


//@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.table-responsive {
		/* IE10+ CSS styles go here */
		overflow: auto;
	}
//}

