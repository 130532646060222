
/* ----------------------------------------------------------------------
 *
 * TIMELINE
 *
 -----------------------------------------------------------------------*/


.timeline{

	margin-top:45px;
	padding-right:15px;
	
	
	
	&__messages{
	
		margin-top:20px;
		
		&__item{
		
			margin-bottom:15px;
			
			h4{
				margin-bottom:5px;
				font-size:0.9rem;
				font-weight:400;
			}
			
			p{
				margin-bottom:5px;
				font-size:0.9rem;
			
			}
		}
	
	}

	&__item{

		border-left:3px solid #f5f5f5;
		margin-left: 15px;
		padding-bottom: 30px;
		position:relative;

		&__marker{

			border-radius: 100%;
			background-color:#ccc;
			width:14px;
			height:14px;
			display: block;
			position:absolute;
			left:-8px;
			top:15px;
			border:3px solid #fff;
		}

		&__inner{

			margin-left: 41px;

		}

		&__title{

			border-radius: 3px 3px 0 0;
			padding:10px 30px;
			background:#fff;
			border:1px solid #dedede;
			color:$color-primary-01;
			position:relative;

			&:hover{
				cursor:hand;
				cursor:pointer;
			}
			
			
		 	&:after{  
		 	
	       	   	content: " ";   	   
	       	   	display:block;
	       	   	position:absolute;
	       	   	right:10px;
	       		top:50%;
	       		margin-top:-17px;
	       	   	width:32px;
	       	   	height:32px;
	       	   	//background-color:#ccc;
	       	   	overflow: hidden;
		        text-indent: -5000em;
		        background-position:center center;
		       	background-repeat:no-repeat;
	       	   	background-image:url(../img/ico-plus-black.png);     		
	       		background-size:14px auto;
	       		opacity:0.3;
	       	   	
	       	}

		}

		&__content{

			border-radius: 0 0 3px 3px;
			padding:30px 30px;
			background:#fff;
			border:1px solid #dedede;
			border-top:none;
			display:none;
		}

		&--active{

			.timeline__item__title{

				background:$color-primary-02;
				color:$color-primary-03;
				border:1px solid $color-primary-02;
				
			 	&:after{ 
			 		background-image:url(../img/ico-plus-white.png);  
			 		opacity:1;
			 	}

			}

			.timeline__item__marker{

				border-radius: 100%;
				background-color:#fff;
				width:16px;
				height:16px;
				display: block;
				position:absolute;
				left:-9px;
				top:15px;
				border:3px solid $color-primary-02;
			}
			
		
			
			

		}
		
		
		&--open{

			.timeline__item__title{

				//background:$color-primary-01;
				//color:$color-primary-03;
				//border:1px solid $color-primary-01;
				
			 	&:after{ 
			 		background-image:url(../img/ico-min-black.png);  
			 		opacity:1;
			 	}

			}

			.timeline__item__content{
				display:block;
			}
			

			
		}

	}

}