.progress-table{


  &__subheading{
    text-transform: uppercase;
    font-weight: 500;
    padding:10px 15px;
    background-color: $color-gray-01;
    display: block;
    //margin-bottom: ($base-unit*0.5);

    span{
      display: inline-block;
      text-transform: none;
      float: right;
      font-size: 0.9rem;
      font-weight: 300;
    //  padding-right: 15px;
    }

  }


  overflow: hidden;
  width: 100%;

  &__table-wrap{
    overflow: hidden;
    width: 100%;
    overflow-x: auto;
      margin-bottom: ($base-unit*1);

  }

  table{
    border-collapse: collapse;
    width: 100%;

  }

  th,td{
    padding:20px 10px;
    border-bottom:1px solid #dedede;



  }

  tbody tr:last-child{
    th,td{
      border-bottom: none;
    }
  }

  th{
    font-weight: 500;
    text-transform: uppercase;
    font-size: 0.9rem;
  }


  &__bar{

    background-color: $color-gray-01;
    height: 8px;
    border-radius: 8px;
    position: relative;

    &--behind span{
      background-color: red;// $color-gray-01;
      width: 33.333%;
    }

    &--ontrack span{
      background-color: orange;// $color-gray-01;
      width: 66.66%;
    }

    &--done span{
      background-color: green;// $color-gray-01;
      width: 100%;
    }

    span{
      position: absolute;
      height: 8px;
      width: 22%;
      border-radius: 8px;
      display: inline-block;



    }

  }

}
