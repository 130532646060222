/* ----------------------------------------------------------------------
 *
 * FAQ
 *
 -----------------------------------------------------------------------*/

body{

}

.faq{

	margin-bottom: 30px;

	&__item{

		//margin-bottom:30px;
		//padding-bottom:30px;
		border-bottom:1px solid #dedede;

		&__question{

				font-weight: 300;
				color:$color-primary-01;
				padding: 15px 0;
		}

		&__answer{
					display:none;
		}

	}

	&__item--active{

		.faq__item__answer{
				display:block;
				padding-bottom: 15px;
		}

	}


}
