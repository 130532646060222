/* ----------------------------------------------------------------------
 *
 * .datalist-utils
 *
 -----------------------------------------------------------------------*/



.datalist-utils{

  z-index:99;
  margin-bottom: ($base-unit*1);
  position: sticky;
  top:85px;
  background-color: #FFFFFF;
  padding:15px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @include respond-to(small){
      flex-direction: row;
  }

  .button, &__sort, &__records{

  }

  &__records{
    padding: 8px 0;
    display: block;
    border-bottom: 2px solid transparent;
  }

  @include respond-to(small){

    .pagenav {
      position: absolute;
      right:0;
      top:50%;
      transform: translateY(-50%);
    }

    &__sort{
      @include respond-to(small){
        width: 220px;
      }
    }

    &__records{

      position: absolute;
      left:0;
      top:50%;
      transform: translateY(-50%);



    }

  }

}

/* ----------------------------------------------------------------------
 *
 * .pagenav
 *
 -----------------------------------------------------------------------*/



.pagenav{

  margin-right: 15px;

  &,li,a{
      display: inline-block;


  }


  a{
    //font-size: 0.9rem;
    padding: 8px 5px;
    margin-left: 5px;
    border-bottom: 2px solid transparent;
    transition: border-bottom 150ms ease-in;

    //border:1px solid #fff;

    &:hover{
      cursor: hand;
      cursor: pointer;
      text-decoration: none;
    }
  }

  li.active a{
    border-bottom: 2px solid $color-gray-01;
  }


}
