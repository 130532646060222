/* ----------------------------------------------------------------------
 *
 * LAYOUT
 *
 -----------------------------------------------------------------------*/
.logo-peugeot {
    width: 158px;
    height: 111px;
    display: block;
    background-image: url("../img/logo-peugeot-dark.svg");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 158px auto;

    &--white {
        background-image: url("../img/logo-peugeot-white.svg");
    }
}
//body{
.page-wrap {
    margin-top:80px;
    //padding-top: 115px;
    //@include respond-to(small) {
    //    padding-top: 115px;
    //}
}

.header {
    position: fixed;
    z-index: 888;
    top: 0;
    right: 0;
    width: 100%;
    background-color: $color-primary-03;
    //background-color: $color-gray-02;
    border-bottom: 1px solid #dedede;
    height: 85px;
    @include respond-to(small) {
        margin: 0 0 30px;
    }

    .wrap {
        height: 100%;
        position: relative;
    }

    &__page-title {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        color: $color-primary-01;
        font-weight: 800;
        font-size: 2rem;
    }

    .nav-wrap {
        position: absolute;
        z-index: 999;
        top: 50%;
        right: 35px;
        transform: translateY(-50%);
        display: none;
        @include respond-to(small) {
            display: block;
        }
    }

    .global-nav,
    .util-nav {
        &,
        li,
        ul {
            float: left;
            display: inline-block;
        }

        li.active {
            a {
                color: $color-primary-03;
            }
        }

        a {
            font-size: 1rem;
            color: rgba(255,255,255,0.5);
            transition: color 200ms ease-in;
            height: 32px;
            line-height: 32px;

            &:hover {
                text-decoration: none;
                color: $color-primary-03;
            }
        }
    }

    .util-nav li:hover .util-nav__dropdown {
        display: block;
    }

    .util-nav__notifications .util-nav__dropdown {
        width: 320px;
    }

    .util-nav__dropdown {
        display: none;
        position: absolute;
        right: -2px;
        top: 30px;
        width: 200px;
        background-color: #fff;
        border: 1px solid #dedede;
        border-radius: 3px;
        padding: 8px;
        /*
      &:before{

         content:'';
         display: block;
         width: 0;
         height: 0;
         border-left: 8px solid transparent;
         border-right: 8px solid transparent;
         border-bottom: 8px solid #fff;
         position: absolute;
         top:-7px;
         right:8px;

      }*/
        a,
        li,
        ul {
            display: block;
            float: none;
            width: 100%;
            margin: 0;
            padding: 0;
        }

        a {
            width: 100% !important;
            background-color: #fff !important;
            color: $color-primary-01 !important;
            overflow: visible !important;
            text-indent: 0 !important;
            padding: 0 15px;
            transition: padding 200ms ease-in;
            font-size: 1.3rem;

            &:hover {
            }
        }
    }

    .util-nav {
        ul {
            margin: 0;
        }
        li {
            position: relative;
        }

        a,
        span {
            width: 32px;
            display: block;
            overflow: hidden;
            text-indent: -5000em;
            background-position: center center;
            background-repeat: no-repeat;
            text-decoration: none;
            opacity: 0.6;
            transition: opacity 200ms ease-in;

            &:hover {
                opacity: 1;
                cursor: hand;
                cursor: pointer;
            }
        }

        &__notifications {
            &--new {
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    right: -7px;
                    top: -7px;
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    background-color: $color-primary-02;
                }
            }

            a,
            span {
                background-image: url("../img/ico-notification-black.png");
                background-size: 23px auto;
            }
        }

        &__myaccount > a {
            background-image: url("../img/ico-user-black-line.png");
            background-size: 24px auto;
        }
        &__manual > a {
            background-image: url("../img/ico-pdf-black.png");
            background-size: 24px auto;
            width:30px;
        }
    }
}
/* ----------------------------------------------------------------------
 *
 * NOTIFICATIONS
 *
 -----------------------------------------------------------------------*/
.notifications {
    //padding:30px;
    &__item {
        margin: 0;
        padding: 15px 30px;
        font-size: 0.9rem;
    }
}
/* ----------------------------------------------------------------------
 *
 * layout
 *
 -----------------------------------------------------------------------*/
.layout {
    background-color: $color-gray-01;
    padding: 30px 10px;
    @include respond-to(large) {
        padding: 30px ($base-unit*2);
    }
}
