/* ----------------------------------------------------------------------
 *
 * BUTTON
 *
 -----------------------------------------------------------------------*/
.button {
    display: inline-block;
    //border-radius: 3px;
    background-color: $color-primary-02;
    color: $color-primary-03;
    padding: 0 30px;
    border: none;
    height: 45px;
    line-height: 45px;
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    //border-bottom: 2px solid darken($color-primary-02,40%);
    width: 100%;
    @include respond-to(small) {
        width: 220px;
    }

    &:hover {
        transition: all 200ms ease-in;
        text-decoration: none;
        cursor: hand;
        cursor: pointer;
        background-color: darken( $color-primary-02, 10% );
    }

    &--white {
        border: 1px solid rgba(255,255,255,0.5);
        color: #fff;
        background: transparent;

        &:hover {
            background-color: $color-primary-02;
            border: 1px solid $color-primary-02;
            color: #fff;
        }
    }

    &--secondary {
        border: 1px solid $color-primary-01;
        color: $color-primary-01;
        background: transparent;

        &:hover {
            background-color: $color-primary-02;
            border: 1px solid $color-primary-02;
            color: #fff;
        }
    }

    &--full {
        width: 100%;
    }

    &--small {
        font-size: 0.8rem;
        height: 40px;
        line-height: 40px;
    }
}

.marginauto {
    margin-left:auto;
}

.btn-red {
    background-color:red;
    &:hover {
        background-color:darkred;
    }
}

.btn-green {
    background-color:#5cb85c;
    &:hover {
        background-color:#3d8b3d;
    }
}
.btn-orange {
    background-color:#FF7F50;
    &:hover {
        background-color:#FF4500;
    }
}

.uib-day {
    .btn-default[disabled] {
        opacity: 0.2 !important;
        color:black;
    }

    .btn-default {
        //border: 1px solid black;
        //background-color:#5466aa;
        //color:white;
    }
}

