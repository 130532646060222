.filepreview{


  margin-bottom: $base-unit;
  &__title{
    display: block;
    margin-bottom: 10px;
    font-size: 1.1rem;
    color:$color-primary-01;
  }

  &__meta{
    display: block;
    margin-bottom: 20px;
  }

  &__specs{
    @include clearfix();
    dt,dd{
        margin-bottom: 5px;
        font-size:1em;
    }
    dt{
      float: left;
      clear: left;
      width: 100px;
    }

    dd{
       margin: 0 0 0 110px;
    }

  }

}

.filedropzone{

  padding: 30px;
  border:1px dotted #ccc;
  text-align: center;
  //height: 100%;

}
