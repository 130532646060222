/* ----------------------------------------------------------------------
 *
 * PRODUCT-DETAIL
 *
 -----------------------------------------------------------------------*/

.product-detail{

  line-height: 1.6rem;

  @include respond-to(small){
    @include clearfix();



    &__image, &__info{
        float:left;
        width:50%;
    }

    &__info{
      padding-left:60px;

    }

  }


  &__title{
    margin-bottom: 10px;
  }

  &__price{
    margin-bottom: 30px;
    font-size:1.4rem;
    color:$color-primary-01;
  }

  &__benefits{
    margin-bottom: 30px;
    li{
      margin: 0 0 10px 0;
      padding-left:33px;
      
	  background-position:  center left;
   	  background-repeat:no-repeat;
   	  background-image:url(../img/ico-check-black.png);     		
	  background-size:18px auto;
      
    }
  }

  &__description{
    margin-bottom: 30px;
  }


}
