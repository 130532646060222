.data-compare {
    width: 100%;
    position: relative;

    &__row {
        padding: 20px 30px;
        @include respond-to(small) {
            padding: 20px 30px;
        }
        text-align: left;
        vertical-align: middle;
        position: relative;
        width: 100%;

        &:last-child {
            border-bottom: none;
        }
        @include respond-to(small) {
             @include clearfix();
            border-bottom: 1px solid #dedede;
        }
    }
    @include respond-to(small) {
        &__cell1 {
            width: 33%;
            float: left;
        }

        &__cell2 {
            width: 33%;
            float: left;
        }

        &__cell3 {
            width: 33%;
            float: left;
        }
    }

    &__label {
        color: $color-primary-01;
        font-weight: 400;
        margin-bottom: 8px;
    }

    &__orignal {
        span {
            display: block;
        }

        &__value {
            //color:$color-primary-01;
            margin-bottom: 15px;
        }

        &__annotation {
            font-size: 0.9rem;
            color: $color-gray-01;
        }
    }

    &__new {
        &__annotation {
            font-size: 0.9rem;
            color: $color-gray-01;
            display: block;
        }

        &__input {
            //display: none;
        }
    }

    .form__input {
        margin-bottom: 0;
    }

    &__steps {
        background-color: #ccc;
        padding: 30px $base-unit;
        text-align: left;
        @include clearfix();

        .button {
            clear: both;
            margin-left: 30px;
            float: left;
        }

        .data-compare {
            &__row {
                &:last-child {
                    border-bottom: 1px solid #dedede !important;
                }
            }
        }
    }

    &__footer {
        padding: 30px $base-unit;
        text-align: right;
        @include clearfix();

        .button {
            margin-left: 30px;
            float: right;
        }

        &__next-record {
            float: left;
            line-height: 45px;
            height: 45px;
            position: relative;
            padding-left: 30px;
            @include clearfix();

            label {
                float: left;
                display: block;
            }

            input[type='checkbox'] {
                position: absolute;
                left: 0;
                top: 15px;
            }
        }

        &__authorize {
            float: right;
            line-height: 45px;
            height: 45px;
            position: relative;
            padding-right: 30px;
            @include clearfix();

            label {
                float: right;
                display: block;
            }

            input[type='checkbox'] {
                position: absolute;
                right: 0;
                top: 15px;
            }
        }
    }
}
// cleaning data

.flex-data {
    width: 100%;

    & form {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-bottom: 1px solid #dedede;
        flex-wrap: wrap;
        padding: 20px;
        @include respond-to(small) {
            flex-direction: row;
        }
    }

    &__row {
        padding: 10px;
        text-align: left;
        vertical-align: middle;
        position: relative;
        width: 100%;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: start;
    }

    &:last-child {
        border-bottom: none;
    }

    &__cell1 {}

    &__cell2 {
        width: 60%;
        display: flex;
        justify-content: flex-end;
    }

    & .form__input {
        width: 100%;
        text-align: right;

        textarea {
            height: auto;
            min-height: 100px;
            resize: vertical;
        }
    }

    &__cell3 {}

    &__label {
        color: $color-primary-01;
        font-weight: 400;
        margin-bottom: 8px;
    }

    &__orignal {
        span {
            display: block;
        }

        &__value {
            //color:$color-primary-01;
            margin-bottom: 15px;
        }

        &__annotation {
            font-size: 0.9rem;
            color: $color-gray-01;
        }
    }

    &__new {
        &__annotation {
            font-size: 0.9rem;
            color: $color-gray-01;
            display: block;
        }

        &__input {
            //display: none;
        }
    }

    .form__input {
        margin-bottom: 0;
    }

    &__steps {
        background-color: #ccc;
        padding: 30px $base-unit;
        text-align: left;

        .button {
            clear: both;
            margin-left: 30px;
            float: left;
        }
    }
}
