
/* ----------------------------------------------------------------------
 *
 * LOGIN
 *
 -----------------------------------------------------------------------*/

body.page-login{

  padding-top: 30px;
  @include respond-to(medium){
    padding-top: 0;
  }

}


 html, body.page-login{

  @include respond-to(medium){
    height:100%;
  }
   background-color: #fff;

 }


 .login{


   	.logo-peugeot{
   		margin: 0 auto;
   		margin-bottom:45px;
   	}


     @include respond-to(medium){
        height:100%;
     }

    &__visual{

      display: none;
      @include respond-to(medium){
        display: block;
      }

      float:left;
      width: 50%;
      height:100%;
      background-position: center center;
      background-size: cover;
      background-image: url('../img/login-visual.jpg');
    }


    &__form{


      @include respond-to(medium){

          margin:0;
          width:100%;
          background-color:#fff;
          position:relative;
          float:left;
          width:50%;
          height:100%;

      }


      form{

        width:calc(100% - 60px);
        max-width:420px;
        margin: 0 auto;

        @include respond-to(small){
          position: absolute;
          left:50%;
          top:50%;
          transform: translate(-50%,-50%);
          height:500px;
          margin: 0;

        }

        h3{
          font-weight:500;
          font-size:1.2rem;
          text-align: center;
          margin-bottom: 45px;
          text-transform: uppercase;
        }

        .button{
          margin:0 0 30px 0;

        }

      }

      &__password-request{
        text-align: center;
        margin-bottom: 30px;
        font-size:0.9rem;
        display: block;

      }

    }

     .social-login{

        p{
          text-align: center;
          margin-bottom: 30px;
          font-size:0.9rem;
        }

        .button{

          width:48%;
          margin-right:4%;
          float:left;

          &:last-child{
            margin-right:0;
          }

        }

     }

 }
