/* ----------------------------------------------------------------------
 *
 * PANEL
 *
 -----------------------------------------------------------------------*/
.panel {
	border: 1px solid #dedede;
	background-color: $color-primary-03;
	margin: 0 0 30px;
	border-radius: 0;
	box-shadow: none;

	&__inner {
		padding: 40px;
		line-height: 1.6rem;

		&--no-padding {
			padding: 0;
		}
	}

	&__title {
		padding: 20px 30px;
		margin-bottom: 0;
		border-bottom: 1px solid #dedede;
		background-color: #fff; //$color-gray-03;
		//text-transform: uppercase;
		font-size: 1rem;
		font-weight: 400;
		color: $color-primary-01;
		position: relative;
		border-radius: 0;
		text-transform: uppercase;

		&:after {
			content: " ";
			display: block;
			position: absolute;
			right: 10px;
			top: 50%;
			margin-top: -17px;
			width: 32px;
			height: 32px;
			overflow: hidden;
			text-indent: -5000em;
			background-position: center center;
			background-repeat: no-repeat;
			background-image: url("../img/ico-min-black.png");
			background-size: 14px auto;
			opacity: 0.3;
		}
	}

	&--closed {
		.panel__title {
			&:after {
				background-image: url("../img/ico-plus-black.png");
			}

			&:hover {
				cursor: hand;
				cursor: pointer;
			}
			border-bottom: none;
		}

		.panel__inner {
			display: none;
		}
	}
}

.panel__flex {
	& .panel__inner {
		padding: 20px;
		line-height: 1.6rem;
		width: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		@include respond-to(smalldesktop) {
			flex-direction: column;
		}

		& .sort_group {
			display: flex;
			margin-bottom: 20px;
			width: auto;
			justify-content: space-between;
			@include respond-to(smalldesktop) {
				width: 100%;
				flex-direction: row;
				justify-content: flex-start;
			}

			& .input-group-addon {
				display: flex;
				overflow: hidden;
				justify-content: center;
				align-items: center;
				min-height: 30px;
			}

			& select {

				background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					background: none;
				}	

				-moz-appearance: none;
				-webkit-appearance: none;
				appearance: none;
				/* and then whatever styles you want*/
				height: 30px;
				min-width: 200px;
				width: 100%;
				padding: 5px;
				border: 1px solid #CCC;

			}
		}

		& .location_group {
			display: flex;
			margin-bottom: 20px;
			width: auto;
			justify-content: space-between;
			@include respond-to(smalldesktop) {
				width: 100%;
				flex-direction: row;
				justify-content: flex-start;
			}
			width: 20%;
			min-width: 215px;
			@include respond-to(smalldesktop) {
				width: 100%;
			}
		}

		& .date_group {
			display: flex;
			margin-bottom: 20px;
			width: auto;
			justify-content: space-between;
			position: relative;
			@include respond-to(smalldesktop) {
				width: 100%;
				flex-direction: row;
				justify-content: flex-start;
			}
			width: 20%;
			min-width: 215px;
			@include respond-to(smalldesktop) {
				width: 100%;
			}
		}

		& .button {
			margin-top: -6px;
			@include respond-to(smalldesktop) {
				margin-top: 0;
			}
		}
	}
}

.entypo-calendar {
    width: 25px;
    display: block;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
	position: absolute;
top: 3px;
}

.entypo-calendar:after {
    content: url(../img/calendar.svg);
    background-size: 60%;
    width: 20px;
    height: 20px;
    opacity: 0.3;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}
.date_group:hover .entypo-calendar:after {
    opacity: 1;
    transition: opacity .3s ease;
}

input#daterange3 {
	padding-left: 30px;
	height: 30px;
}
