

.notification-bar{

  position: fixed;
  z-index: 888;

  bottom:30px;
  left:50%;

  width: 300px;
  padding: 20px 60px 20px 20px;

  text-align: center;


  transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  border-radius: 3px;
  //visibility: hidden;

  &:hover{
    cursor: hand;
    cursor: pointer;
  }

  &--warning{
      color: #8a6d3b;
      background-color: #fcf8e3;
      border-color: #faebcc;
  }

  &--succes{
      color: #3c763d;
      background-color: #dff0d8;
      border-color: #d6e9c6;
  }

  &--info{
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
  }

  &--danger{
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }

  &:hover  .notification-bar__close{
    opacity: 1;
    cursor: pointer;
    cursor: hand;
  }

  &__close{


    display: block;
    overflow: hidden;
    text-indent: -5000em;
    width: 45px;
    height: 45px;
    position: absolute;
    right:5px;
    top:50%;
    transform: translateY(-50%);
    background-image: url(../img/ico-close-black.png);
    background-size: 14px auto;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.2;
    transition: opacity 150ms ease-in;

  }


  &--hide{

    transform: translate(-50%,200%);

  }

  &--show{

    transform: translate(-50%,0%);

  }


}
