

.datagrid{




	&__no-records{
		padding: $base-unit;
		text-align: center;
	}

	.datagrid__center{
		text-align: center;
	}


	.datagrid__description{

		font-size: 1rem;
		color: $color-primary-01;
		display: block;

	}

	&__meta{

		//font-size: 0.9rem;
		color: $color-gray-01;
		display: block;

	}


	// simple icon to show status

	&__imported{


		display:inline-block;
		width:45px;
		height:45px;
		text-indent:-5000em;
		overflow:hidden;
		background-size: 18px auto;
		background-position: center center;
		background-repeat: no-repeat;
		background-image: url(../img/ico-imported.png);
		opacity: 0.3;
		transition: opacity 150ms ease-in;

		&:hover{
				opacity: 1;
		}

	}


	thead{

		th{
			font-weight:400;
			color:$color-primary-01;
		}


	}

	table{

	width:100%;

	}

	tbody tr{

		&:last-child{
			td{
				border-bottom:none;
			}
		}


		&:nth-child(2n){
			td{
				background-color:#fbfbfb;

			}
		}


	}

	th,td{
		padding:10px 30px;
		text-align:left;
		vertical-align:middle;
		border-right:1px solid #dedede;
		border-bottom:1px solid #dedede;

		&:last-child{
			border-right:0;
		}
	}


	&__actions{

		&__edit a{
			display:inline-block;
			width:45px;
			height:45px;
			//background-color:red;
			text-indent:-5000em;
			overflow:hidden;
		      background-size: 18px auto;
		      background-position: center center;
		      background-repeat: no-repeat;
			background-image: url(../img/ico-edit.png);
			opacity: 0.3;
			transition: opacity 150ms ease-in;

			&:hover{
					opacity: 1;
			}

		}


		&__view a{
			display:inline-block;
			width:45px;
			height:45px;
			//background-color:red;
			text-indent:-5000em;
			overflow:hidden;
		      background-size: 18px auto;
		      background-position: center center;
		      background-repeat: no-repeat;
			background-image: url(../img/ico-view.png);
			opacity: 0.3;
			transition: opacity 150ms ease-in;

			&:hover{
					opacity: 1;
			}

		}

	}


}
