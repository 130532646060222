	.dashboard_chart {
		height: 400px;
		@include respond-to(mobile){
			height: 600px;
		}
	}

.toplist {
	&__item {
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid #dedede;
		display: table;
		table-layout: fixed;
		width: 100%;

		&__col {
			display: table-cell;

			&:nth-child(1) {
				width: 50px;
				vertical-align: middle;
				text-align: left;
			}
		}

		&:last-child {
			border-bottom: none;
		}

		span {
			display: block;
		}

		&__title {
			color: $color-primary-01;
			font-size: 1rem;
			font-weight: 400;
			margin-bottom: 0;
		}

		&__meta {
			font-size: 0.9rem;
			//color: lighten($color-primary-01,40%);
		}

		&__position {
			font-size: 1rem;
			border: 2px solid #dedede;
			border-radius: 100%;
			display: inline-block;
			width: 45px;
			height: 45px;
			line-height: 42px;
			text-align: center;
			margin-left: -22px;
		}
	}
}

.weekcal {
	&__item {
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid #dedede;

		&:last-child {
			border-bottom: none;
		}

		span {
			display: block;
		}

		&__title {
			color: $color-primary-01;
			font-size: 1rem;
			font-weight: 400;
			margin-bottom: 0;
		}

		&__date {
			font-size: 0.9rem;
		}
	}
}
